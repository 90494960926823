import { Box, Flex, Heading, Icon, Link, Text } from '@chakra-ui/react';
import NextLink from 'next/link';

interface DropdownMenuElementProps {
  label: string;
  description: string;
  path: string;
  icon: any;
  color?: string;
  background?: string;
}

export const DropdownItem = ({ label, description, path, icon, color, background }: DropdownMenuElementProps) => {
  return (
    <Link
      as={NextLink}
      display="flex"
      alignItems="center"
      width="336px"
      height="96px"
      py={4}
      pr={8}
      pl={4}
      backgroundColor="none"
      borderRadius="12px"
      role="group"
      _hover={{
        background: 'violet.50',
        textDecoration: 'none',
        color: 'purple.500',
      }}
      href={path}
    >
      <Flex
        justifyContent="center"
        alignItems="center"
        flexShrink="0"
        height="40px"
        width="40px"
        backgroundColor={background ?? 'violet.50'}
        borderRadius="8px"
        mr={6}
      >
        <Icon as={icon} aria-hidden="true" color={color} />
      </Flex>
      <Box>
        <Heading as="h2" fontFamily="inherit" color="purple.500" fontSize={16} lineHeight={6} pb={1} fontWeight={500}>
          {label}
        </Heading>
        <Text color="purple.400" _groupHover={{ color: 'purple.500' }} fontSize={12} aria-hidden="true">
          {description}
        </Text>
      </Box>
    </Link>
  );
};
