import React, { PropsWithChildren } from 'react';

import { Variants, motion } from 'framer-motion';

type AnimateWhenInViewWrapperProps = PropsWithChildren & {
  className?: string;
  variant?: 'smooth' | 'snap';
  amount?: number;
  additionalDelay?: number;
};

const DEFAULT_SCROLL_SHOW_RATIO = 0.25;

export const AnimateWhenInViewWrapper = ({
  children,
  className,
  variant = 'smooth',
  amount,
  additionalDelay = 0,
}: AnimateWhenInViewWrapperProps) => {
  return (
    <motion.div
      className={className || ''}
      initial="offscreen"
      whileInView="onscreen"
      viewport={{ once: true, amount: amount || DEFAULT_SCROLL_SHOW_RATIO }}
    >
      <motion.div variants={variant === 'snap' ? snap : getSmoothParams(additionalDelay)}>{children}</motion.div>
    </motion.div>
  );
};

const getSmoothParams = (delay: number): Variants => ({
  offscreen: {
    y: 20,
    opacity: 0,
  },
  onscreen: {
    y: 0,
    opacity: 1,
    transition: {
      ease: 'easeOut',
      duration: 0.8,
      delay,
    },
  },
});

const snap: Variants = {
  offscreen: {
    scale: 0,
  },
  onscreen: {
    scale: 1,
    transition: {
      duration: 0.2,
      delay: 1,
    },
  },
};
