import parse, { Element, HTMLReactParserOptions, domToReact } from 'html-react-parser';

const parseOptions: HTMLReactParserOptions = {
  replace(domNode) {
    if (domNode instanceof Element) {
      return <>{domToReact(domNode.children, parseOptions)}</>;
    }
  },
};

export function dropHTMLTags(content: string) {
  return parse(content, parseOptions);
}

// An alternative version of "dropHTMLTags()" that works outside JSX. If possible, please use "dropHTMLTags()"
export function stripHtml(content: string) {
  return content.replace(/<\/?("[^"]*"|'[^']*'|[^>])*(>|$)/g, '');
}
