import { defineStyle, defineStyleConfig } from '@chakra-ui/react';

const flushed = defineStyle({
  borderColor: 'purple.300',
  fontSize: '18px',
  _placeholder: {
    color: 'purple.300',
  },
});

export const textareaTheme = defineStyleConfig({
  variants: { flushed },
});
