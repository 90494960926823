import { Avatar, Flex } from '@chakra-ui/react';

import { UserFragment } from '@/graphql/generated';

export interface UsersAvatarsProps {
  users: UserFragment[];
}

/**
 * It supports only max of two users
 */
export const UsersAvatars = ({ users }: UsersAvatarsProps) => {
  return (
    <Flex
      sx={{
        '& > *': {
          _notFirst: {
            ml: -4,
            mt: 4,
          },
        },
      }}
    >
      {users.map((user) => (
        <Avatar key={user.id} name={user.attributes?.username} src={user.attributes?.avatar?.data?.attributes?.url} />
      ))}
    </Flex>
  );
};
