import { FocusEvent } from 'react';

import { Box, Icon, Link, Text, useDisclosure } from '@chakra-ui/react';
import Arrow from 'images/icons/arrow-down.svg';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';

import { LOCALES } from '@/utils/constants';
import { GeneralPath } from '@/utils/models';

import { getNavbarItemStyles, isMenuActive, navbarItemUnderlineStyles } from '../Navbar.utils';
import { ServicesDropdown } from './ServicesDropdown';
import { servicesMenuItems } from './servicesMenuItems';

export const ServicesDesktopMenu = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { route } = useRouter();

  const { t } = useTranslation([LOCALES.COMMON]);

  const handleBlur = (event: FocusEvent<HTMLElement>): void => {
    if (!event.currentTarget.contains(event.relatedTarget)) {
      onClose();
    }
  };

  const isActive = isMenuActive(route, GeneralPath.SERVICES);

  return (
    <Box as="li" listStyleType="none" pr={{ md: 4, lg: 8 }} onBlur={handleBlur}>
      <Link
        as={Text}
        href={undefined}
        aria-haspopup="true"
        color={isOpen || isActive ? 'violet.500' : 'purple.500'}
        onFocus={onOpen}
        onMouseEnter={onOpen}
        onMouseLeave={onClose}
        {...getNavbarItemStyles(isActive, false)}
        _hover={{
          color: 'violet.500',
          textDecoration: 'none',
          _after: {
            transform: 'scaleX(1)',
          },
        }}
        _after={{
          ...navbarItemUnderlineStyles,
          width: 'calc(100% - 24px)',
          transform: isOpen || isActive ? 'scaleX(1)' : 'scaleX(0)',
        }}
      >
        {t('header.services')}
        <Icon
          as={Arrow}
          aria-hidden="true"
          transition="transform 0.2s linear"
          ml={2}
          transform={isOpen ? 'rotateZ(180deg)' : 'rotateZ(0)'}
          fill={isOpen ? 'violet.500' : 'current'}
        />
      </Link>
      <ServicesDropdown subItems={servicesMenuItems} isOpen={isOpen} onOpen={onOpen} onClose={onClose} />
    </Box>
  );
};
