import { useMediaQuery, useTheme } from '@chakra-ui/react';

import { LinksColumn } from '@/components/layouts/Footer/LinksColumn';

import { FooterColumn } from '../FirstColumnAndMobile/FirstColumnAndMobile';

type LastColumnsProps = {
  thirdColumnData?: FooterColumn | null;
  forthColumnData?: FooterColumn | null;
};

export const MobileFooterMenu = ({ thirdColumnData, forthColumnData }: LastColumnsProps) => {
  const theme = useTheme();
  const [isSmallerThanSm] = useMediaQuery(`(max-width: ${theme.breakpoints.sm})`);

  return isSmallerThanSm && forthColumnData && thirdColumnData ? (
    <>
      <LinksColumn linksColumnData={forthColumnData} collapsable />
      <LinksColumn linksColumnData={thirdColumnData} mb="12" />
    </>
  ) : null;
};
