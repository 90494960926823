import { Heading, Stack, Text } from '@chakra-ui/react';
import RocketWithStars from 'images/doodles/rocket-with-stars.png';
import { useTranslation } from 'next-i18next';
import Image from 'next/image';

import { Button } from '@/components/atoms';
import { LOCALES } from '@/utils/constants';
import { GeneralPath } from '@/utils/models';

type DropdownConsultationSectionProps = {
  onClose: () => void;
};
export const DropdownConsultationSection = ({ onClose }: DropdownConsultationSectionProps) => {
  const { t } = useTranslation([LOCALES.COMMON]);
  return (
    <Stack
      h="full"
      w="full"
      justifyContent="center"
      alignItems="center"
      spacing="60px"
      px={{ base: '45px', xl: '60px' }}
    >
      <Image src={RocketWithStars} alt="rocket with stars" width="218" height="193" />
      <Stack alignItems="center" spacing="22px">
        <Heading color="purple.500" textAlign="center" variant="text-md/lineHeight-6/font-semibold">
          {t('dropdownConsultationSection.title')}
          <Text color="purple.500" fontWeight="400">
            {t('dropdownConsultationSection.desc')}
          </Text>
        </Heading>
        <Button
          label={t('dropdownConsultationSection.btnLabel')}
          variant="light"
          size="lg"
          w="190px"
          url={GeneralPath.CONTACT}
          onClick={onClose}
        />
      </Stack>
    </Stack>
  );
};
