import breakpoints from './breakpoints';
import colors from './colors';
import radius from './radius';
import { sizes } from './sizes';
import { spacing } from './spacing';
import typography from './typography';
import zIndices from './z-index';

export const foundations = {
  breakpoints,
  colors,
  radius,
  space: spacing,
  sizes,
  ...typography,
  zIndices,
};
