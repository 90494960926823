import { accordionTheme } from './accordion';
import { avatarTheme } from './avatar';
import { buttonTheme } from './button';
import { checkboxTheme } from './checkbox';
import { headingTheme } from './heading';
import { inputTheme } from './input';
import { popoverTheme } from './popover';
import { tableTheme } from './table';
import { tagTheme } from './tag';
import { textTheme } from './text';
import { textareaTheme } from './textarea';
import { tooltipTheme } from './tooltip';

export { buttonTheme as Button } from './button';
export { headingTheme as Heading } from './heading';
export { textTheme as Text } from './text';

export const components = {
  Button: buttonTheme,
  Heading: headingTheme,
  Text: textTheme,
  Avatar: avatarTheme,
  Tag: tagTheme,
  Accordion: accordionTheme,
  Checkbox: checkboxTheme,
  Popover: popoverTheme,
  Input: inputTheme,
  Textarea: textareaTheme,
  Tooltip: tooltipTheme,
  Table: tableTheme,
};
