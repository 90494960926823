import { Flex, Icon, useMediaQuery } from '@chakra-ui/react';
import Arrow from 'images/icons/arrow.svg';
import { useTranslation } from 'next-i18next';

import { Button } from '@/components/atoms';
import { LOCALES } from '@/utils/constants';
import { NavbarItem } from '@/utils/models';

import { buttonWithArrowCommonStyles } from '../../styles';
import { ExpandableMobileMenuItem } from '../ExpandableMobileMenuItem';

interface ExpandableMobileMenuProps {
  labelTransKey: string;
  isOpen: boolean;
  onMenuClose: () => void;
  onSubmenuClose: () => void;
  subItems?: NavbarItem[];
}

export const ExpandableMobileMenu = ({
  labelTransKey,
  subItems,
  isOpen,
  onMenuClose,
  onSubmenuClose,
}: ExpandableMobileMenuProps) => {
  const [isOrientationLandscape] = useMediaQuery('(orientation: landscape)');
  const { t } = useTranslation([LOCALES.COMMON]);

  return (
    <Flex
      as="ul"
      display={isOpen ? 'flex' : 'none'}
      position="absolute"
      top="0"
      left="0"
      right="0"
      height={isOrientationLandscape ? '100vh' : '100%'}
      flexDirection="column"
      justifyContent="space-between"
      gap={isOrientationLandscape ? 0 : 6}
      backgroundColor="beige.50"
      visibility={isOpen ? 'visible' : 'hidden'}
      zIndex="mobileMenuFirstNesting"
      aria-label={`${t(labelTransKey)} submenu`}
      aria-hidden={!isOpen}
    >
      <Button
        color="violet.500"
        label={t(labelTransKey)}
        {...buttonWithArrowCommonStyles}
        pb={6}
        width="100%"
        variant="navbar"
        onClick={onSubmenuClose}
        leftIcon={
          <Icon as={Arrow} color="violet.500" mr={4} transform="rotate(-90deg)" transition="transform 0.2s linear" />
        }
      />
      <Flex gap={6} flexDirection="column">
        {subItems?.map((item) => {
          const { labelTransKey, path, subItems, isMobileButton } = item;
          return (
            <ExpandableMobileMenuItem
              key={labelTransKey}
              labelTransKey={labelTransKey}
              path={path}
              subItems={subItems}
              setIsMenuOpen={onMenuClose}
              isMobileButton={isMobileButton}
            />
          );
        })}
      </Flex>
    </Flex>
  );
};
