import { Flex, Grid } from '@chakra-ui/react';

import { ServicesMenuItem } from './ServicesMenuItem';
import { ServicesMenuLink } from './servicesMenuItems';

interface DropdownProps {
  subItems?: ServicesMenuLink[];
  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
  dropdownArrowLeft?: string;
}

export const ServicesDropdown = ({ subItems, isOpen, onOpen, onClose, dropdownArrowLeft }: DropdownProps) => {
  return (
    <Flex
      width={{ md: '740px', lg: '940px' }}
      position="absolute"
      left="-44px"
      pt={6}
      _after={{
        content: '""',
        position: 'absolute',
        left: ['140px', '140px', '140px', dropdownArrowLeft || '64px'],
        top: '14px',
        height: '36px',
        width: '36px',
        backgroundColor: 'white',
        borderRadius: '6px',
        transform: 'rotate(45deg)',
      }}
      display={isOpen ? 'flex' : 'none'}
      onMouseEnter={onOpen}
      onMouseLeave={onClose}
    >
      <Grid gridTemplateColumns="1fr 1fr 1fr" py="40px" px="32px" gap="48px" bg="white" borderRadius="24px" w="full">
        {subItems?.map((el) => (
          <ServicesMenuItem key={el.title} title={el.title} icon={el.icon} links={el.links} mainLink={el.mainLink} />
        ))}
      </Grid>
    </Flex>
  );
};
