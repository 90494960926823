import { inputAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(inputAnatomy.keys);

const flushed = definePartsStyle({
  field: {
    borderColor: 'purple.300',
    fontSize: '18px',
    _placeholder: {
      color: 'purple.300',
    },
  },
});

export const inputTheme = defineMultiStyleConfig({ variants: { flushed } });
