import { NextSeo } from 'next-seo';
import { OpenGraphArticle } from 'next-seo/lib/types';
import { useRouter } from 'next/router';

import { MetadataFragment } from '@/graphql/generated';
import { useGlobal, useLocale } from '@/hooks';
import { OG_IMAGE } from '@/utils/constants';
import { getImageMimeTypeByURL } from '@/utils/images';

interface SeoProps {
  metadata: MetadataFragment;
  article?: OpenGraphArticle;
}

export const Seo = ({ metadata, article }: SeoProps) => {
  const locale = useLocale();
  const { asPath } = useRouter();
  const globalMetadata = useGlobal(locale)?.metadata;

  const { metaTitle, metaDescription, ogImage } = {
    ...globalMetadata,
    ...metadata,
  };

  const url = `${process.env.NEXT_PUBLIC_WEBSITE_URL}${asPath}`;
  const siteName = 'Startup House';

  return (
    <NextSeo
      title={metaTitle}
      titleTemplate={`%s | ${siteName}`}
      description={metaDescription}
      canonical={url}
      openGraph={{
        type: article ? 'article' : 'website',
        url,
        title: metaTitle,
        description: metaDescription,
        images: [
          {
            url: ogImage?.data?.attributes?.url || '',
            width: ogImage?.data?.attributes?.width || OG_IMAGE.WIDTH,
            height: ogImage?.data?.attributes?.height || OG_IMAGE.HEIGHT,
            alt: metaTitle,
            type: getImageMimeTypeByURL(ogImage?.data?.attributes?.url || ''),
          },
        ],
        article,
        siteName,
      }}
      twitter={{
        handle: '@startuphouse_',
        cardType: 'summary_large_image',
      }}
      additionalLinkTags={[
        {
          rel: 'icon',
          href: '/favicon.ico',
        },
        {
          rel: 'apple-touch-icon',
          href: '/apple-touch-icon.png',
          sizes: '180x180',
        },
      ]}
    />
  );
};
