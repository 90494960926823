import { Flex } from '@chakra-ui/react';
import { useTranslation } from 'next-i18next';

import { LOCALES } from '@/utils/constants';
import { NavbarItem } from '@/utils/models';

import { DropdownItem } from '../DropdownItem';

interface DropdownItemsProps {
  label: string;
  subItems?: NavbarItem[];
  isOpen: boolean;
  onClose: () => void;
}
export const DropdownItems = ({ label, subItems, isOpen, onClose }: DropdownItemsProps) => {
  const { t } = useTranslation([LOCALES.COMMON]);
  return (
    <Flex
      as="ul"
      aria-label={`${label} submenu`}
      aria-hidden={!isOpen}
      aria-expanded={isOpen}
      flexDirection="column"
      width="100%"
      listStyleType="none"
      position="relative"
      py={8}
      px={{ base: 4, xl: 14 }}
      gap={4}
    >
      {subItems &&
        subItems.map((item) => {
          const { labelTransKey, path, icon, descTransKey, isMobileButton, color, background } = item;

          if (!isMobileButton) {
            return (
              <Flex as="li" width="100%" borderRadius="8px" cursor="pointer" key={labelTransKey} onClick={onClose}>
                <DropdownItem
                  label={t(labelTransKey)}
                  path={path}
                  icon={icon}
                  description={descTransKey ? t(descTransKey) : ''}
                  color={color}
                  background={background}
                />
              </Flex>
            );
          }
        })}
    </Flex>
  );
};
