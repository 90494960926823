import { Global } from '@emotion/react';

export const Fonts = () => (
  <Global
    styles={`
      @font-face {
        font-family: 'GuyotPress';
        font-style: normal;
        font-weight: 400;
        font-display: swap;
        src: url('/assets/fonts/GuyotPress-Regular.woff2') format('woff2'), 
              url('/assets/fonts/GuyotPress-Regular.woff') format('woff');
      }
      
      @font-face {
        font-family: 'Covered By Your Grace';
        font-style: normal;
        font-weight: 400;
        font-display: swap;
        src: url('/assets/fonts/CoveredByYourGrace-Regular.woff2') format('woff2'),
             url('/assets/fonts/CoveredByYourGrace-Regular.woff') format('woff');
      }
    `}
  />
);
