import { ThemeConfig, extendTheme } from '@chakra-ui/react';

import { components } from './components';
import { typographyVariants } from './components/typography-variants';
import { foundations } from './foundations';
import { styles } from './styles';

const config: ThemeConfig = {
  useSystemColorMode: false,
  initialColorMode: 'light',
};

export const theme = extendTheme({
  ...foundations,
  components,
  styles,
  config,
  textStyles: typographyVariants,
});
