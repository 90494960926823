import { useEffect } from 'react';

import { ChakraProps, chakra, shouldForwardProp } from '@chakra-ui/react';
import { isValidMotionProp, motion, useAnimation } from 'framer-motion';

import { GradientBackgroundProps } from '@/utils/models/background-props';

const ChakraBox = chakra(motion.div, {
  shouldForwardProp: (prop) => isValidMotionProp(prop) || shouldForwardProp(prop),
});

export const GradientBackground = (props: ChakraProps & GradientBackgroundProps) => {
  const { isInView, ...chakraProps } = props;
  const controls = useAnimation();

  useEffect(() => {
    if (isInView) {
      controls.start({
        background: [
          'radial-gradient(50% 50% at 50% 50%, #E5DCF4 0%, rgba(229, 220, 244, 0) 100%)',
          'radial-gradient(50% 50% at 50% 50%, #E5DCF4 10%, rgba(229, 220, 244, 0) 100%)',
          'radial-gradient(50% 50% at 50% 50%, #E5DCF4 20%, rgba(229, 220, 244, 0) 100%)',
          'radial-gradient(50% 50% at 50% 50%, #E5DCF4 10%, rgba(229, 220, 244, 0) 100%)',
          'radial-gradient(50% 50% at 50% 50%, #E5DCF4 0%, rgba(229, 220, 244, 0) 100%)',
        ],
        transform: [
          'scale3d(1, 1, 1) rotate(0deg) translateX(150px) rotate(0deg)',
          'scale3d(1.1, 1.1, 1.1) rotate(90deg) translateX(150px) rotate(-90deg)',
          'scale3d(1.2, 1.2, 1.2) rotate(180deg) translateX(150px) rotate(-180deg)',
          'scale3d(1.1, 1.1, 1.1) rotate(270deg) translateX(150px) rotate(-270deg)',
          'scale3d(1, 1, 1) rotate(360deg) translateX(150px) rotate(-360deg)',
        ],
        transformStyle: 'preserve-3d',
      });
    } else {
      controls.stop();
    }
  }, [controls, isInView]);

  return (
    <ChakraBox
      animate={controls}
      //@ts-ignore
      transition={{
        duration: 10,
        ease: 'linear',
        repeat: Infinity,
        repeatType: 'loop',
      }}
      {...chakraProps}
    />
  );
};
