import { As, Flex, Icon, Link } from '@chakra-ui/react';
import NextLink from 'next/link';

interface SocialMediaItemsProps {
  socialItems: {
    icon: string;
    url: string;
  }[];
}
export const SocialMediaItems = ({ socialItems }: SocialMediaItemsProps) => {
  return (
    <Flex mt={4} justifyContent="center" gap={6}>
      {socialItems.map((item) => {
        if (item) {
          const { url, icon } = item;

          return (
            <Link
              key={url}
              as={NextLink}
              href={url}
              width="16px"
              height="16px"
              position="relative"
              aria-label={new URL(url || '').hostname}
            >
              {url && (
                <Icon
                  as={icon as unknown as As}
                  alt=""
                  width="16"
                  height="16"
                  style={{
                    objectFit: 'contain',
                    overflow: 'hidden',
                    width: '16px',
                    height: '16px',
                  }}
                />
              )}
            </Link>
          );
        }
      })}
    </Flex>
  );
};
