import { useCallback, useEffect, useState } from 'react';

import { Box, Icon, useTheme } from '@chakra-ui/react';
import Arrow from 'images/icons/arrow.svg';

import { HEADER_HEIGHT } from '@/utils/constants';

import { Button } from '../Button';

const BUTTON_VISIBLE_SCROLL_VALUE = 300;

export const ScrollToTop = () => {
  const [scrollYValue, setScrollYValue] = useState(typeof window !== 'undefined' ? window.scrollY : 0);
  const [isButtonVisible, setIsButtonVisible] = useState(false);
  const theme = useTheme();

  const toggleButtonVisible = useCallback(() => {
    const scrollTopValue = document.documentElement.scrollTop;

    if (scrollTopValue > BUTTON_VISIBLE_SCROLL_VALUE && scrollYValue > window.scrollY) {
      setIsButtonVisible(true);
    } else if (scrollTopValue <= BUTTON_VISIBLE_SCROLL_VALUE) {
      setIsButtonVisible(false);
    }

    setScrollYValue(window.scrollY);
  }, [scrollYValue]);

  useEffect(() => {
    setScrollYValue(window.scrollY);

    document.addEventListener('scroll', toggleButtonVisible);

    return () => document.removeEventListener('scroll', toggleButtonVisible);
  }, [toggleButtonVisible]);

  const handleScrollToTop = () => {
    scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  return (
    <Box
      position="fixed"
      top={`calc(${HEADER_HEIGHT} + ${theme.sizes[8]})`}
      left="50%"
      transform={`translateX(-50%) scale(${isButtonVisible ? '1' : '0'})`}
      transition="transform 0.2s linear"
      width="48px"
      height="32px"
      zIndex="4"
    >
      <Button
        label=""
        rightIcon={<Icon as={Arrow} fill="white" />}
        onClick={handleScrollToTop}
        width="100%"
        height="100%"
        borderRadius="100px"
        backgroundColor="violet.500"
        _active={{ backgroundColor: 'violet.600' }}
        _hover={{ backgroundColor: 'violet.600' }}
        sx={{
          '& > span': {
            m: 0,
          },
        }}
      />
    </Box>
  );
};
