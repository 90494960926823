import { useCallback } from 'react';

const dateFormatter = Intl.DateTimeFormat('en', {
  day: '2-digit',
  month: 'short',
  year: 'numeric',
});

const longDateFormatter = Intl.DateTimeFormat('en', {
  day: '2-digit',
  month: 'long',
  year: 'numeric',
});

function formatDate(formatter: Intl.DateTimeFormat, date: DateLike) {
  return formatter.format(normalizeDate(date)).replace(/\s/g, '\xa0');
}

const normalizeDate = (date: DateLike) => (typeof date === 'string' ? new Date(date) : date);

export const useDateFormatter = () => {
  return useCallback((date: DateLike) => {
    return formatDate(dateFormatter, date);
  }, []);
};

export const useLongDateFormatter = () => {
  return useCallback((date: DateLike) => {
    return formatDate(longDateFormatter, date);
  }, []);
};
