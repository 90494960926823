import { Link } from '@chakra-ui/react';
import LogoMobile from 'images/icons/sdh-logo-mobile.svg';
import NextLink from 'next/link';

import { navbarItems } from '@/utils/models';

type HeaderLogoProps = {
  onClick: () => void;
};
export const HeaderLogo = ({ onClick }: HeaderLogoProps) => {
  const { home } = navbarItems;

  return (
    <Link
      as={NextLink}
      href={home.path}
      display="flex"
      aria-label="Startup Development House logo. Click redirect to home page"
      onClick={onClick}
    >
      <LogoMobile width="52px" height="24px" />
    </Link>
  );
};
