import { PropsWithChildren, ReactNode } from 'react';

interface ConditionalWrapperProps extends PropsWithChildren {
  condition: boolean;
  wrapperIfConditionTrue: (children: ReactNode) => JSX.Element;
  wrapperIfConditionFalse?: (children: ReactNode) => JSX.Element;
}

export const ConditionalWrapper = ({
  condition,
  wrapperIfConditionTrue,
  wrapperIfConditionFalse = (children) => <>{children}</>,
  children,
}: ConditionalWrapperProps) => (condition ? wrapperIfConditionTrue(children) : wrapperIfConditionFalse(children));
