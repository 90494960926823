import { useRouter } from 'next/router';

import { GeneralPath, ServicesPath } from '@/utils/models';

export const usePathUtils = () => {
  const router = useRouter();

  let path = router.asPath;

  const queryParamsIndex = path.indexOf('?');
  if (queryParamsIndex !== -1) {
    path = path.slice(0, queryParamsIndex);
  }
  const hashParamsIndex = path.indexOf('#');
  if (hashParamsIndex !== -1) {
    path = path.slice(0, hashParamsIndex);
  }

  const pathSegments = path.split('/').filter((segment) => segment !== '');
  const isArticlePage = pathSegments[0] === 'blog' && pathSegments.length === 2;
  const isCaseStudyPage = pathSegments[0] === 'case-studies' && pathSegments.length === 2;
  const isServicePage = pathSegments[0] === 'services' && pathSegments.length === 2;

  const isHomePage = router.asPath === GeneralPath.HOME;
  const isAboutUsPage = router.asPath === GeneralPath.ABOUT_US;
  const isDesignPage = router.asPath === ServicesPath.DESIGN;
  const isContactPage = router.asPath === GeneralPath.CONTACT;

  const parseSegment = (label: string) => {
    const parsedLabel = label.replaceAll('-', ' ');
    return parsedLabel[0].toUpperCase() + parsedLabel.slice(1);
  };

  return {
    pathSegments,
    isHomePage,
    isArticlePage,
    isCaseStudyPage,
    isAboutUsPage,
    isDesignPage,
    isServicePage,
    isContactPage,
    parseSegment,
  };
};
