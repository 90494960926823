import React from 'react';

import { Box, Flex, Icon, Text } from '@chakra-ui/react';
import Link from 'next/link';

import { ServicesMenuLink } from './servicesMenuItems';

export const ServicesMenuItem = ({ title, icon, links, mainLink }: ServicesMenuLink) => {
  return (
    <Flex gap="10px">
      <Box borderRadius="8px">
        <Icon as={icon} aria-hidden="true" w="32px" h="32px" />
      </Box>
      <Flex flexDir="column" gap="8px">
        <Link href={mainLink}>
          <Text
            fontSize="16px"
            lineHeight="26px"
            color="purple.500"
            fontWeight="500"
            _hover={{ textDecoration: 'underline' }}
          >
            {title}
          </Text>
        </Link>
        {links.map((link) => (
          <Link href={link.path} key={link.path}>
            <Text fontSize="12px" lineHeight="18px" color="purple.500" _hover={{ textDecoration: 'underline' }}>
              {link.label}
            </Text>
          </Link>
        ))}
      </Flex>
    </Flex>
  );
};
