import { Flex } from '@chakra-ui/layout';
import { Box, useDisclosure } from '@chakra-ui/react';

import { CONTENT_MARGIN_X, CONTENT_MAX_WIDTH_PX, HEADER_HEIGHT } from '@/utils/constants';
import { navbarItems } from '@/utils/models';

import { HeaderDesktopButton } from './HeaderDesktopButton';
import { HeaderLogo } from './HeaderLogo';
import { HeaderMobileButton } from './HeaderMobileButton';
import { MobileMenu } from './MobileHeaderContent/MobileMenu/MobileMenu';
import { Navbar } from './Navbar';

export const Header = () => {
  const { isOpen, onClose, onToggle } = useDisclosure();
  const { home: _home, ...otherItems } = navbarItems;

  return (
    <Flex
      as="header"
      height={HEADER_HEIGHT}
      position="fixed"
      alignItems="center"
      justifyContent="center"
      width="100%"
      top="0"
      left="0"
      zIndex="sticky"
      backgroundColor="beige.50"
      borderBottom="1px solid"
      borderBottomColor="purple.100"
    >
      <Flex
        justifyContent="space-between"
        alignItems="center"
        w="full"
        maxW={CONTENT_MAX_WIDTH_PX}
        mx={CONTENT_MARGIN_X}
      >
        <HeaderLogo onClick={onClose} />
        <Box
          display={{
            base: 'none',
            md: 'block',
          }}
        >
          <Navbar />
        </Box>
        <Box
          display={{
            base: 'block',
            md: 'none',
          }}
        >
          <HeaderMobileButton isOpen={isOpen} onToggle={onToggle} />
          <MobileMenu isMenuOpen={isOpen} menuItems={otherItems} setIsMenuOpen={onClose} />
        </Box>
        <Box
          display={{
            base: 'none',
            md: 'block',
          }}
        >
          <HeaderDesktopButton />
        </Box>
      </Flex>
    </Flex>
  );
};
