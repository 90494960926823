import { FocusEvent } from 'react';

import { Box, Icon, Link, Text, useDisclosure } from '@chakra-ui/react';
import Arrow from 'images/icons/arrow-down.svg';
import { useTranslation } from 'next-i18next';
import NextLink from 'next/link';

import { LOCALES } from '@/utils/constants';
import { NavbarItem } from '@/utils/models';

import { Dropdown } from '../../Dropdown';
import { getNavbarItemStyles, navbarItemUnderlineStyles } from '../Navbar.utils';

interface NavbarItemWithDropdownProps {
  labelTransKey: string;
  path: string;
  subItems?: NavbarItem[];
  isLink: boolean;
  isActive: boolean;
  dropdownArrowLeft?: string;
}

export const NavbarItemWithDropdown = ({
  labelTransKey,
  path,
  subItems,
  isLink,
  isActive,
  dropdownArrowLeft,
}: NavbarItemWithDropdownProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const { t } = useTranslation([LOCALES.COMMON]);

  const handleBlur = (event: FocusEvent<HTMLElement>): void => {
    if (!event.currentTarget.contains(event.relatedTarget)) {
      onClose();
    }
  };

  return (
    <Box as="li" listStyleType="none" pr={{ md: 4, lg: 8 }} onBlur={handleBlur}>
      <Link
        as={!isLink ? Text : NextLink}
        href={isLink ? path : undefined}
        aria-haspopup="true"
        color={isOpen || isActive ? 'violet.500' : 'purple.500'}
        onFocus={onOpen}
        onMouseEnter={onOpen}
        onMouseLeave={onClose}
        {...getNavbarItemStyles(isActive, false)}
        _hover={{
          color: 'violet.500',
          textDecoration: 'none',
          _after: {
            transform: 'scaleX(1)',
          },
        }}
        _after={{
          ...navbarItemUnderlineStyles,
          width: 'calc(100% - 24px)',
          transform: isOpen || isActive ? 'scaleX(1)' : 'scaleX(0)',
        }}
      >
        {t(labelTransKey)}
        <Icon
          as={Arrow}
          aria-hidden="true"
          transition="transform 0.2s linear"
          ml={2}
          transform={isOpen ? 'rotateZ(180deg)' : 'rotateZ(0)'}
          fill={isOpen ? 'violet.500' : 'current'}
        />
      </Link>
      <Dropdown
        label={t(labelTransKey)}
        subItems={subItems}
        isOpen={isOpen}
        onOpen={onOpen}
        onClose={onClose}
        dropdownArrowLeft={dropdownArrowLeft}
      />
    </Box>
  );
};
