import { Box, SystemStyleObject } from '@chakra-ui/react';

const commonHamburgerStyles: SystemStyleObject = {
  content: "''",
  position: 'absolute',
  top: 0,
  left: 0,
  width: '30px',
  borderTop: '4px solid',
  borderColor: 'violet.500',
  transition: 'transform 0.2s linear',
  borderRadius: '3px',
};
interface HamburgerIconProps {
  isMenuOpen: boolean;
}

export const HamburgerIcon = ({ isMenuOpen }: HamburgerIconProps) => {
  return (
    <Box
      as="span"
      position="relative"
      width="30px"
      height="3px"
      border="none"
      borderTop="solid"
      borderColor="violet.500"
      borderWidth={isMenuOpen ? '0' : '4px'}
      borderRadius="3px"
      backgroundColor="transparent"
      transition="border-width 0.1s linear"
      transform="translateY(-9px)"
      _after={{
        ...commonHamburgerStyles,
        transform: isMenuOpen ? 'rotate(-45deg) translateX(-5px) translateY(6px)' : 'translateY(5px)',
      }}
      _before={{
        ...commonHamburgerStyles,
        transform: isMenuOpen ? 'rotate(45deg) translateX(2px) translateY(-6px)' : 'translateY(14px)',
        transformOrigin: 'left',
      }}
    />
  );
};
