import AIIcon from 'images/icons/ai.svg';
import CoopIcon from 'images/icons/coopmodel.svg';
import DesignIcon from 'images/icons/design.svg';
import IdeationIcon from 'images/icons/ideation.svg';
import MaintenanceIcon from 'images/icons/maintenece.svg';
import SoftwareIcon from 'images/icons/softwaredev.svg';

export interface ServicesMenuLink {
  title: string;
  icon: any;
  mainLink: string;
  links: {
    path: string;
    label: string;
  }[];
}

export const servicesMenuItems: ServicesMenuLink[] = [
  {
    title: 'Ideation',
    icon: IdeationIcon,
    mainLink: '/pages/ideation-services',
    links: [
      { path: '/pages/product-discovery', label: 'Product Discovery' },
      { path: '/pages/direction-check', label: 'Direction Check' },
      { path: '/pages/scope-building', label: 'Scope Building' },
      { path: '/pages/user-testing', label: 'User Testing' },
    ],
  },
  {
    title: 'Design',
    icon: DesignIcon,
    mainLink: '/pages/design-services',
    links: [
      { path: '/pages/ux-design', label: 'UX Design' },
      { path: '/pages/ui-design', label: 'UI Design' },
      { path: '/pages/product-design', label: 'Product Design' },
    ],
  },
  {
    title: 'Software Development',
    icon: SoftwareIcon,
    mainLink: '/pages/software-development-services',
    links: [
      { path: '/pages/web-development-services', label: 'Web Development' },
      { path: '/pages/mobile-development-services', label: 'Mobile Development' },
      { path: '/pages/no-code-services', label: 'No Code Development' },
      { path: '/pages/mvp-development-services', label: 'MVPs' },
    ],
  },
  {
    title: 'Maintenance',
    icon: MaintenanceIcon,
    mainLink: '/pages/maintenance-services',
    links: [
      { path: '/pages/quality-assurance-services', label: 'Quality Assurance' },
      { path: '/pages/cloud-services', label: 'Cloud Services' },
    ],
  },
  {
    title: 'Cooperation Models',
    icon: CoopIcon,
    mainLink: '/pages/cooperation-models',
    links: [
      { path: '/pages/team-augmentation-services', label: 'Team Augmentation' },
      { path: '/pages/bot-services', label: 'BOT' },
    ],
  },
  {
    title: 'AI & Data Science',
    icon: AIIcon,
    mainLink: '/pages/ai-data-science',
    links: [
      { path: '/pages/ai-services', label: 'AI Services' },
      { path: '/pages/data-science-services', label: 'Data Science' },
    ],
  },
];
