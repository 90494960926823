/* eslint-disable jsx-a11y/alt-text */

/* eslint-disable @next/next/no-img-element */
import { ReactElement, ReactNode, useEffect, useState } from 'react';

import { ChakraProvider } from '@chakra-ui/react';
import { Hydrate, QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { NextPage } from 'next';
import { appWithTranslation } from 'next-i18next';
import { AppProps } from 'next/app';
import { useRouter } from 'next/router';
import Script from 'next/script';

import { Fonts, PreloadedImages } from '@/components/layouts';
import { theme } from '@/theme';
import {
  COOKIEYES_SHOW_BTN_CLASS,
  COOKIEYES_URL,
  INLINKS_SCRIPT_URL,
  PATHS_TO_SHOW_COOKIEYES_BUTTON,
} from '@/utils/constants';
import { GoogleTagManagerNoscript, GoogleTagManagerScript } from '@/utils/google-tag-manager';
import { HotjarScript } from '@/utils/hotjar/hotjar';
import { LinkedinPixelScript } from '@/utils/linkedin-pixel/linkedin-pixel';
import { LinkedinPixelNoScript } from '@/utils/linkedin-pixel/linkedin-pixel-noscript';
import { parseUTMParamsFromURL } from '@/utils/marketing';
import { GeneralPath } from '@/utils/models';
import { PixelFbNoscript, PixelFbScript } from '@/utils/pixel-fb';

import nextI18NextConfig from '../../next-i18next.config.js';

export type NextPageWithLayout<P = {}, IP = P> = NextPage<P, IP> & {
  getLayout?: (page: ReactElement) => ReactNode;
};

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout;
};

parseUTMParamsFromURL(); // read user params only once during initial page loading

function _App({ Component, pageProps }: AppPropsWithLayout) {
  const router = useRouter();

  // adding class only to these pages, where cookieYes cookie button should be shown
  useEffect(() => {
    if (document) {
      if (PATHS_TO_SHOW_COOKIEYES_BUTTON.includes(router.route as GeneralPath)) {
        document.body.classList.add(COOKIEYES_SHOW_BTN_CLASS);
      } else {
        document.body.classList.remove(COOKIEYES_SHOW_BTN_CLASS);
      }
    }
  }, [router.route]);

  const [queryClient] = useState(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            refetchOnWindowFocus: false,
            staleTime: Infinity,
          },
        },
      }),
  );

  const isDevelopment = process.env.NODE_ENV === 'development';
  const getLayout = Component.getLayout ?? ((page) => page);

  return (
    <>
      <QueryClientProvider client={queryClient}>
        <Hydrate state={pageProps.dehydratedState}>
          <ChakraProvider theme={theme}>
            <Fonts />
            <PreloadedImages />
            {getLayout(<Component {...pageProps} />)}
          </ChakraProvider>
          {/*<ReactQueryDevtools />*/}
        </Hydrate>
      </QueryClientProvider>
      {!isDevelopment ? (
        <>
          <Script src={COOKIEYES_URL} />
          <Script src={INLINKS_SCRIPT_URL} />
          <GoogleTagManagerScript />
          <GoogleTagManagerNoscript />
          <PixelFbScript />
          <PixelFbNoscript />
          <HotjarScript />
          <LinkedinPixelScript />
          <LinkedinPixelNoScript />
        </>
      ) : null}
    </>
  );
}

export default appWithTranslation(_App, nextI18NextConfig);
