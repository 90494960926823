import { Flex, Link } from '@chakra-ui/layout';
import { useTranslation } from 'next-i18next';
import NextLink from 'next/link';

import { Button } from '@/components/atoms';
import { LOCALES } from '@/utils/constants';

import { getNavbarItemStyles, navbarItemUnderlineStyles } from '../Navbar.utils';

interface NavbarItemProps {
  labelTransKey: string;
  path: string;
  setIsMenuOpen?: () => void;
  isMobileButton?: boolean;
  isMobile?: boolean;
  isActive: boolean;
}

export const NavbarItem = ({
  labelTransKey,
  path,
  setIsMenuOpen,
  isMobile,
  isMobileButton,
  isActive,
}: NavbarItemProps) => {
  const { t } = useTranslation([LOCALES.COMMON]);
  return (
    <Flex as="li" pr={{ md: 6, lg: 8 }}>
      {!isMobileButton ? (
        <Link
          prefetch={false}
          as={NextLink}
          href={path}
          color={isActive ? 'violet.500' : 'purple.500'}
          width={isMobile ? '100%' : 'auto'}
          {...getNavbarItemStyles(isActive, isMobile)}
          _hover={{
            color: 'violet.500',
            _after: {
              transform: isMobile ? 'scaleX(0)' : 'scaleX(1)',
            },
          }}
          _after={{
            ...navbarItemUnderlineStyles,
            transform: isActive ? 'scaleX(1)' : 'scaleX(0)',
          }}
          onClick={() => setIsMenuOpen && setIsMenuOpen()}
        >
          {t(labelTransKey)}
        </Link>
      ) : (
        <Button
          label={t(labelTransKey)}
          size="lg"
          variant="light"
          w="100%"
          url={path}
          onClick={() => setIsMenuOpen?.()}
        />
      )}
    </Flex>
  );
};
