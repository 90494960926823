const colors = {
  beige: {
    50: '#FBF8F0',
    100: '#F9F4E6',
    200: '#F0E5C2',
    300: '#E6D399',
    400: '#D2B047',
    500: '#8F7523',
    600: '#665419',
  },
  lime: {
    50: '#F9FFE6',
    100: '#F6FCD9',
    200: '#E4F89B',
    300: '#E8F79D',
    400: '#DFFA74',
    500: '#D9FC59',
    600: '#BAEB04',
    700: '#92B903',
    800: '#6B8702',
    900: '#435502',
  },
  purple: {
    50: '#F2F1F3',
    100: '#DDDBE1',
    200: '#C3BFC9',
    300: '#8F889B',
    400: '#504972',
    500: '#261F56',
    600: '#1E1843',
  },
  violet: {
    50: '#F3F0FA',
    100: '#E5DCF4',
    200: '#C8B6E7',
    '200_alpha50': '#C8B6E780',
    300: '#AB8FDA',
    400: '#7D54D6',
    500: '#5F2DD3',
    600: '#4B23A9',
    700: '#391B7E',
    800: '#261254',
    900: '#13092A',
  },
};

export default colors;
