import { PropsWithChildren, forwardRef } from 'react';

import { Box, BoxProps } from '@chakra-ui/react';

import { CONTENT_MAX_WIDTH_PX } from '@/utils/constants';

type SectionContentWrapperProps = PropsWithChildren & BoxProps;

export const SectionContentWrapper = forwardRef<HTMLDivElement, SectionContentWrapperProps>((props, ref) => {
  const { children, ...boxProps } = props;

  return (
    <Box
      as="div"
      ref={ref}
      margin="0 auto"
      maxWidth={CONTENT_MAX_WIDTH_PX}
      paddingX={{ base: 5, sm: 6, lg: 20, '3xl': 24 }} // 20px, 32px, 80px
      paddingY={{ base: 16, sm: 20 }} // 64px, 80px, 80px, 80px
      position="relative"
      {...boxProps}
    >
      {children}
    </Box>
  );
});

SectionContentWrapper.displayName = 'SectionContentWrapper';
