import { Button as ChakraButton, ButtonProps as ChakraButtonProps, forwardRef } from '@chakra-ui/react';
import NextLink from 'next/link';

export interface ButtonProps extends ChakraButtonProps {
  url?: string | null;
  label?: string;
}

export const Button = forwardRef((props: ButtonProps, ref) => {
  const { url, label, children, ...chakraProps } = props;

  const buttonProps = {
    ...chakraProps,
    children: label || children,
    ref,
  };

  if (!url) {
    return <ChakraButton {...buttonProps} />;
  }

  return (
    <NextLink href={url} legacyBehavior passHref>
      <ChakraButton as="a" fontSize="lg" {...buttonProps} />
    </NextLink>
  );
});
