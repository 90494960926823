import { Styles } from '@chakra-ui/theme-tools';

export const styles: Styles = {
  global: {
    body: {
      bg: 'beige.50',
    },
    // when cookieYes overlay is present & open - body scroll is blocked
    'body:has(.cky-modal-open)': {
      overflow: 'hidden',
    },
    'div.calendly-overlay': {
      backgroundColor: 'rgba(31,31,31,0.8)',
      '.calendly-popup': {
        width: '100vw',
        height: '100vh',
        maxWidth: 'unset',
        maxHeight: 'unset',
        '@media (max-width: 975px)': {
          top: 0,
        },
      },
    },
    'strong, b': {
      fontWeight: 600,
    },
    '@media (max-height: 800px)': {
      'div > iframe[src*="hcaptcha"]': {
        transform: 'translateY(-80px)',
      },
    },
  },
};
