import { PropsWithChildren } from 'react';

import { Box } from '@chakra-ui/react';
import { OpenGraphArticle } from 'next-seo/lib/types';
import { useRouter } from 'next/router';

import { Breadcrumbs } from '@/components/functional/Breadcrumbs';
import { MetadataFragment } from '@/graphql/generated';
import { HEADER_HEIGHT } from '@/utils/constants';
import { pathsToChangeDefaultLayoutOverflow } from '@/utils/models';

import { Header } from './Header';
import { PreviewModeBanner } from './PreviewModeBanner';
import { Seo } from './Seo';

interface DefaultLayoutProps extends PropsWithChildren {
  metadata: MetadataFragment;
  preview?: boolean;
  article?: OpenGraphArticle;
}

export const DefaultLayout = (props: DefaultLayoutProps) => {
  const { metadata, preview, children, article } = props;
  const { pathname } = useRouter();

  const isOverflowUnset = pathsToChangeDefaultLayoutOverflow.find((path) => path === pathname);

  return (
    <>
      <Seo metadata={metadata} article={article} />
      <Header />

      <Box as="main" overflowY="clip" overflowX={!!isOverflowUnset ? 'clip' : 'hidden'} mt={HEADER_HEIGHT}>
        <Breadcrumbs />
        {preview && <PreviewModeBanner />}
        {children}
      </Box>
    </>
  );
};
