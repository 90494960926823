import { UTM_SOURCE_LS_KEY } from '@/utils/constants';

export const parseUTMParamsFromURL = () => {
  try {
    if (
      typeof window !== 'undefined' &&
      window.location.search?.trim().toLocaleLowerCase().includes('utm_') &&
      localStorage
    ) {
      const params = new URLSearchParams(window.location.search);

      const utmParams: string[] = [];
      params.forEach((value, k) => {
        const key = k?.trim().toLowerCase();
        if (key.startsWith('utm_')) {
          utmParams.push(`${key.toUpperCase()}: ${value}`);
        }
      });

      utmParams.push(`ENTRY_URL: ${window.location.pathname}`);

      localStorage.setItem(UTM_SOURCE_LS_KEY, JSON.stringify(utmParams));
    }
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e);
  }
};

export const getParsedUTMParams = () => {
  try {
    const utm = localStorage.getItem(UTM_SOURCE_LS_KEY);
    const utmParams = utm ? (JSON.parse(utm) as string[]) : [];
    if (typeof window !== 'undefined') {
      utmParams.push(`SUBMIT_URL: ${window.location.pathname}`);
    }
    return utmParams.join(', ');
  } catch (e) {
    return null;
  }
};
