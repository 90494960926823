import { UploadFile } from '@/graphql/generated';
import { StrapiImageFormat, StrapiImageType, StrapiMediaAttributes } from '@/typings/strapi';

import { stripHtml } from './drop-html-tags';

export const getImageSrcFromAttributes = (
  attributes: StrapiMediaAttributes | undefined | null,
  format?: StrapiImageFormat | null,
): string => {
  if (!format) {
    return attributes?.url || '';
  }
  return attributes?.formats?.[format]?.url || attributes?.url || '';
};

export const getImageMimeTypeByURL = (url: string): string => {
  const MIME_TYPES = {
    png: 'image/png',
    gif: 'image/gif',
    jpeg: 'image/jpeg',
    jpg: 'image/jpeg',
    webp: 'image/webp',
  };
  if (!url) {
    return MIME_TYPES.jpeg;
  }
  const pathArr = url.split('.');
  const extension = pathArr[pathArr.length - 1];
  return MIME_TYPES[extension as 'jpg'] || MIME_TYPES.jpeg;
};

export const getImageSrcWithBlurhash = (
  image:
    | (Pick<UploadFile, 'placeholder'> & {
        url?: string;
      })
    | undefined
    | null,
  format?: StrapiImageFormat | null,
): {
  placeholder: 'blur' | 'empty';
  blurDataURL: string | undefined;
  src: string;
} => {
  return {
    placeholder: image?.placeholder ? 'blur' : 'empty',
    blurDataURL: image?.placeholder || '',
    src: getImageSrcFromAttributes(image, format),
  };
};

// Keep it as a separate function because of `jsx-a11y/alt-text` rule.
// Eslint-plugin-jsx-a11y can't parse "alt" when we use spread syntax (...) for <Image />
export const getImageAlt = (
  image: Pick<UploadFile, 'alternativeText' | 'caption'> | undefined | null,
  fallback?: string,
): string => {
  return image?.alternativeText || image?.caption || stripHtml(fallback || '');
};

export const getImageSizeFromAttributes = (
  strapiImage: StrapiImageType | undefined,
  format?: StrapiImageFormat | null,
): {
  width: number;
  height: number;
} => {
  const imageAttributes = strapiImage?.data?.attributes;
  if (!format) {
    return {
      width: imageAttributes?.width ?? 0,
      height: imageAttributes?.height ?? 0,
    };
  }
  return {
    width: imageAttributes?.formats?.[format]?.width ?? imageAttributes?.width ?? 0,
    height: imageAttributes?.formats?.[format]?.height ?? imageAttributes?.height ?? 0,
  };
};

export const parseStrapiImage = (strapiImage: StrapiImageType | undefined, format?: StrapiImageFormat | null) => ({
  ...getImageSrcWithBlurhash(strapiImage?.data?.attributes, format),
  ...getImageSizeFromAttributes(strapiImage),
});
