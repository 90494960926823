import DribbbleIcon from 'icons/dribbble.svg';
import FacebookIcon from 'icons/facebook.svg';
import GithubIcon from 'icons/github.svg';
import InstagramIcon from 'icons/instagram.svg';
import LinkedinIcon from 'icons/linkedin.svg';
import TwitterIcon from 'icons/twitter.svg';
import { useTranslation } from 'next-i18next';

import { EXTERNAL_URL, LOCALES } from '@/utils/constants';
import { GeneralPath, ServicesPath } from '@/utils/models';

export const useFooterData = () => {
  const { t } = useTranslation(LOCALES.COMMON);

  const columnContactUs = {
    heading: t('footer.columnContactUs.heading'),
    links: [
      {
        label: t('footer.columnContactUs.ourOffice'),
        url: EXTERNAL_URL.TEL_OFFICE,
      },
      {
        label: t('footer.columnContactUs.newBusiness'),
        url: EXTERNAL_URL.TEL_BUSINESS,
      },
      {
        label: t('footer.columnContactUs.email'),
        url: EXTERNAL_URL.EMAIL,
      },
    ],
  };
  const columnFollowUs = {
    heading: t('footer.columnFollowUs.heading'),
    links: [
      {
        icon: InstagramIcon,
        url: EXTERNAL_URL.INSTAGRAM,
      },
      {
        icon: FacebookIcon,
        url: EXTERNAL_URL.FACEBOOK,
      },
      {
        icon: LinkedinIcon,
        url: EXTERNAL_URL.LINKEDIN,
      },
      {
        icon: DribbbleIcon,
        url: EXTERNAL_URL.DRIBBBLE,
      },
      {
        icon: GithubIcon,
        url: EXTERNAL_URL.GITHUB,
      },
      {
        icon: TwitterIcon,
        url: EXTERNAL_URL.TWITTER,
      },
    ],
  };
  const columnCompany = {
    heading: t('footer.columnCompany.heading'),
    links: [
      {
        label: t('footer.columnCompany.linkCaseStudies'),
        url: GeneralPath.CASE_STUDIES,
      },
      {
        label: t('footer.columnCompany.linkBlog'),
        url: GeneralPath.BLOG,
      },
      {
        label: t('footer.columnCompany.linkAboutUs'),
        url: GeneralPath.ABOUT_US,
      },
      {
        label: t('footer.columnCompany.linkCareers'),
        url: EXTERNAL_URL.CAREERS,
      },
      {
        label: t('footer.columnCompany.linkContactUs'),
        url: GeneralPath.CONTACT,
      },
      {
        label: t('footer.columnCompany.linkGlossary'),
        url: GeneralPath.GLOSSARY_ALL,
      },
      {
        label: t('footer.columnCompany.linkPartner'),
        url: EXTERNAL_URL.CAREERS_PARTNER,
      },
    ],
  };
  const columnServices = {
    heading: t('footer.columnServices.heading'),
    links: [
      {
        label: t('footer.columnServices.linkProductDiscovery'),
        url: ServicesPath.PRODUCT_DISCOVERY,
      },
      {
        label: t('footer.columnServices.linkProductDesign'),
        url: ServicesPath.DESIGN,
      },
      {
        label: t('footer.columnServices.linkMVPBuilding'),
        url: ServicesPath.MVP_BUILDING,
      },
      {
        label: t('footer.columnServices.linkNoCode'),
        url: ServicesPath.NO_CODE,
      },
      {
        label: t('footer.columnServices.linkDevelopment'),
        url: ServicesPath.DEVELOPMENT,
      },
    ],
  };

  return {
    columnContactUs,
    columnFollowUs,
    columnCompany,
    columnServices,
  };
};
