import { defineStyle, defineStyleConfig } from '@chakra-ui/react';

import { ButtonVariant } from '@/graphql/generated';

const variantBlue = defineStyle({
  py: 3,
  px: 6,
  fontWeight: 'medium',
  fontSize: 'text-md',
  lineHeight: 'lineHeight-6',
  borderRadius: '3xl',
  color: 'white',
  backgroundColor: 'violet.500',
  transition: 'color .25s',
  overflow: 'hidden',
  border: '3px solid transparent',
  zIndex: 1,
  '& path': { fill: 'white', transition: 'all .25s' },
  '&:after': {
    backgroundColor: 'lime.500',
    content: '""',
    display: 'block',
    height: '100%',
    width: '102%',
    position: 'absolute',
    left: '-1px',
    top: '0',
    transform: 'translate(-100%, 0) rotate(10deg)',
    transformOrigin: 'top left',
    transition: '.2s transform ease-out .05s',
    willChange: 'transform',
    zIndex: '-1',
    borderRadius: '24px',
  },
  _hover: {
    _disabled: {
      bg: 'purple.500',
    },
    color: 'violet.500',
    '& path': { fill: 'violet.500' },
    '&:after': {
      transform: 'translate(0, 0)',
    },
  },
});

const variantLime = defineStyle({
  ...variantBlue,
  color: 'purple.500',
  backgroundColor: 'lime.500',
  '& path': { fill: 'purple.500', transition: 'all .25s' },
  '&:after': {
    backgroundColor: 'violet.500',
    content: '""',
    display: 'block',
    height: '100%',
    width: '102%',
    position: 'absolute',
    left: '-1px',
    top: '0',
    transform: 'translate(-100%, 0) rotate(10deg)',
    transformOrigin: 'top left',
    transition: '.2s transform ease-out .05s',
    willChange: 'transform',
    zIndex: '-1',
    borderRadius: '24px',
  },
  _hover: {
    _disabled: {
      bg: 'lime.500',
    },
    color: 'white',
    '& path': { fill: 'white' },
    '&:after': {
      transform: 'translate(0, 0)',
    },
  },
});

const variantNavbar = defineStyle({
  justifyContent: 'flex-start',
  padding: 0,
  height: 'auto',
  lineHeight: 7,
  fontSize: 20,
  whiteSpace: 'normal',
  textAlign: 'left',
  color: 'purple.500',
  backgroundColor: 'transparent',
});

const variantSliderLight = defineStyle({
  backgroundColor: 'violet.100',
  borderRadius: '50%',
  width: '48px',
  height: '48px',
  _hover: {
    backgroundColor: 'violet.200',
  },
});

const variantSliderDark = defineStyle({
  backgroundColor: 'violet.500',
  borderRadius: '50%',
  width: '48px',
  height: '48px',
  _hover: {
    backgroundColor: 'violet.600',
  },
  '& svg > path': {
    fill: 'lime.500',
  },
  color: 'violet.700',
});

const variantOutline = defineStyle({
  backgroundColor: 'transparent',
  color: 'violet.500',
  border: '1px solid',
  borderColor: 'violet.500',
  outline: 'violet.500',
  borderRadius: '100px',
  h: 12,
  fontWeight: 600,
  _hover: {
    backgroundColor: 'violet.200_alpha50',
  },
});

const variants = {
  [ButtonVariant.Light]: variantBlue,
  [ButtonVariant.Dark]: variantLime,
  navbar: variantNavbar,
  sliderLight: variantSliderLight,
  sliderDark: variantSliderDark,
  outline: variantOutline,
};

export const buttonTheme = defineStyleConfig({
  variants,
});
