import { defineStyleConfig } from '@chakra-ui/react';

export const avatarTheme = defineStyleConfig({
  sizes: {
    md: {
      container: {
        w: 10,
        h: 10,
      },
    },
    lg: {
      container: {
        w: 18,
        h: 18,
      },
    },
  },
});
