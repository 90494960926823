import { Flex, Icon, Link, Text } from '@chakra-ui/react';
import { useTranslation } from 'next-i18next';
import Image from 'next/image';
import NextLink from 'next/link';

import { CONTENT_MARGIN_X, CONTENT_MAX_WIDTH_PX, LOCALES } from '@/utils/constants';
import { FooterBottomBar } from '@/utils/models';

interface BottomBarProps {
  bottomBarData: FooterBottomBar;
  copyright: string;
}

export const BottomBar = ({ bottomBarData, copyright }: BottomBarProps) => {
  const { icons, links } = bottomBarData;
  const { t } = useTranslation(LOCALES.COMMON);

  return (
    <Flex backgroundColor="black" color="white" w="full" py={12} justifyContent="center">
      <Flex
        maxW={CONTENT_MAX_WIDTH_PX}
        mx={CONTENT_MARGIN_X}
        justifyContent={{
          base: 'left',
          lg: 'space-between',
        }}
        flexDir={{
          base: 'column',
          lg: 'row',
        }}
        flexWrap="wrap"
        width="full"
        gap={{ base: 6, lg: 8 }}
      >
        {/* // TODO: Find another way to list these icons with the correct sizes */}
        <Flex flexWrap="wrap" justifyContent="center" gap={{ base: 3, md: 4 }}>
          <Icon as={Image} src={icons.icons.iconOne} w="105px" h="24px" alt="logo" />
          <Icon as={Image} src={icons.icons.iconTwo} w="82px" h="24px" alt="logo" />
          <Icon as={Image} src={icons.icons.iconThree} w="41px" h="30px" alt="logo" />
          <Icon as={Image} src={icons.icons.iconFour} w="47px" h="24px" alt="logo" />
        </Flex>
        <Text textAlign="center" fontSize={{ base: 'text-xs', xl: 'text-sm', '2xl': 'text-md' }}>
          {copyright}
        </Text>
        <Flex
          flexWrap="wrap"
          fontSize={{ base: 'text-xs', xl: 'text-sm', '2xl': 'text-md' }}
          justifyContent="center"
          gap={6}
        >
          {Object.values(links).map(({ label, url, transKey }) => (
            <Link as={NextLink} key={label} href={url} fontWeight="medium">
              {transKey ? t(`${transKey}`) : label}
            </Link>
          ))}
        </Flex>
      </Flex>
    </Flex>
  );
};
