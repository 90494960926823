import { ChakraProps, Flex } from '@chakra-ui/react';

export const StaticBackground = (props: ChakraProps) => {
  const backgroundCol = !props.backgroundColor ? 'beige-100' : props.backgroundColor;
  return (
    <Flex
      backgroundColor="beige.100"
      position="absolute"
      width="80%"
      height="100%"
      // filter="blur(104px)" <-- don't user filter blur - iOS can't handle it and there is a big render delay
      boxShadow={`0px 0px 60px 70px var(--chakra-colors-${(backgroundCol as string).replace('.', '-') || 'beige-100'})`}
      opacity={0.8}
      borderRadius="100%"
      zIndex={-1}
      {...props}
    />
  );
};
