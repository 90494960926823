import { useTranslation } from 'next-i18next';

import { Button } from '@/components/atoms';
import { LOCALES } from '@/utils/constants';
import { GeneralPath } from '@/utils/models';

export const HeaderDesktopButton = () => {
  const { t } = useTranslation([LOCALES.COMMON]);

  return <Button label={t('header.contact')} variant="light" url={GeneralPath.CONTACT} />;
};
