import { Flex, Icon, useDisclosure, useMediaQuery } from '@chakra-ui/react';
import Arrow from 'images/icons/arrow.svg';
import { useTranslation } from 'next-i18next';

import { Button } from '@/components/atoms';
import { LOCALES } from '@/utils/constants';
import { GeneralPath, NavbarItem } from '@/utils/models';

import { buttonWithArrowCommonStyles } from '../../styles';
import { ExpandableMobileMenu } from '../ExpandableMobileMenu';

interface ExpandableMobileMenuItemProps {
  labelTransKey: string;
  path: string;
  setIsMenuOpen: () => void;
  subItems?: NavbarItem[];
  isMobileButton?: boolean;
}

export const ExpandableMobileMenuItem = ({
  labelTransKey,
  path,
  subItems,
  setIsMenuOpen,
  isMobileButton,
}: ExpandableMobileMenuItemProps) => {
  const [isOrientationLandscape] = useMediaQuery('(orientation: landscape)');
  const { isOpen, onClose, onToggle } = useDisclosure();
  const { t } = useTranslation([LOCALES.COMMON]);

  const handleCloseMenu = () => {
    setIsMenuOpen();
    onClose();
  };

  return (
    <Flex as="li" alignItems="center" key={labelTransKey}>
      {!isMobileButton ? (
        <Button
          label={t(labelTransKey)}
          url={subItems ? undefined : path}
          {...buttonWithArrowCommonStyles}
          width="100%"
          onClick={subItems ? onToggle : handleCloseMenu}
          variant="navbar"
          rightIcon={
            subItems && <Icon as={Arrow} fontSize={20} transition="transform 0.2s linear" transform="rotate(90deg)" />
          }
        />
      ) : (
        <Button
          label={t(labelTransKey)}
          variant="light"
          size="lg"
          url={GeneralPath.CONTACT}
          w="100%"
          mb={isOrientationLandscape ? 4 : 0}
          onClick={subItems ? onToggle : handleCloseMenu}
        />
      )}
      <ExpandableMobileMenu
        subItems={subItems}
        labelTransKey={labelTransKey}
        isOpen={isOpen}
        onMenuClose={handleCloseMenu}
        onSubmenuClose={onClose}
      />
    </Flex>
  );
};
