import {
  Box,
  Collapse,
  Flex,
  GridItem,
  GridItemProps,
  Heading,
  Icon,
  Link,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import Arrow from 'images/icons/arrow-down.svg';
import NextLink from 'next/link';

import { ConditionalWrapper } from '@/components/wrappers';

import { FooterColumn } from '../FirstColumnAndMobile/FirstColumnAndMobile';

interface LinksColumnProps extends GridItemProps {
  linksColumnData: FooterColumn;
  collapsable?: boolean;
}
export const LinksColumn = ({ linksColumnData, collapsable, ...props }: LinksColumnProps) => {
  const { isOpen, onToggle } = useDisclosure();
  const { heading, links } = linksColumnData;

  const textProps = {
    variant: {
      base: 'text-md/lineHeight-6/font-medium',
      md: 'text-md/lineHeight-6/font-medium',
      sm: 'text-xs/lineHeight-4/font-medium',
    },
    color: 'purple.500',
  };

  const headingProps = collapsable
    ? {
        ...textProps,
        mb: '6',
        onClick: onToggle,
      }
    : {
        variant: 'text-xs/lineHeight-4/font-medium/0.75rem',
        color: 'purple.400',
        pb: '4',
      };

  return (
    <GridItem mt={{ base: 6, md: 0 }} gridColumn={{ base: '1/-1', xs: 'auto', md: 'auto' }} {...props}>
      {!heading ? null : (
        <Heading as="h3" {...headingProps}>
          {heading}
          {collapsable ? (
            <Flex as="span" float="right" h="full" alignItems="center">
              <Icon
                as={Arrow}
                transition="transform 0.2s linear"
                transform={isOpen ? 'rotateZ(180deg)' : 'rotateZ(0)'}
              />
            </Flex>
          ) : null}
        </Heading>
      )}
      <ConditionalWrapper
        condition={!!collapsable}
        wrapperIfConditionTrue={(children) => (
          <Collapse in={isOpen} animateOpacity>
            {children}
          </Collapse>
        )}
      >
        <Flex
          as="ul"
          listStyleType="none"
          flexDirection="column"
          gap={{ base: 6, sm: 2, md: 6 }}
          pl={collapsable ? 8 : 0}
        >
          {links.map((link) => {
            if (link) {
              return (
                <Box as="li" key={link.label}>
                  <Text {...textProps}>
                    <Link as={NextLink} href={link.url} prefetch={false}>
                      {link.label}
                    </Link>
                  </Text>
                </Box>
              );
            }
            return null;
          })}
        </Flex>
      </ConditionalWrapper>
    </GridItem>
  );
};
