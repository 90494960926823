import { Flex, Icon, useDisclosure, useMediaQuery } from '@chakra-ui/react';
import Arrow from 'images/icons/arrow.svg';
import { useTranslation } from 'next-i18next';

import { Button } from '@/components/atoms';
import { LOCALES } from '@/utils/constants';
import { GeneralPath } from '@/utils/models';

import { buttonWithArrowCommonStyles } from '../../styles';
import { ServicesMenuItem } from './ServicesMenuItem';
import { servicesMenuItems } from './servicesMenuItems';

interface ServicesMobileMenuItemProps {
  setIsMenuOpen: () => void;
}

export const ServicesMobileMenu = ({ setIsMenuOpen }: ServicesMobileMenuItemProps) => {
  const [isOrientationLandscape] = useMediaQuery('(orientation: landscape)');
  const { isOpen, onClose, onToggle } = useDisclosure();
  const { t } = useTranslation([LOCALES.COMMON]);

  const handleCloseMenu = () => {
    setIsMenuOpen();
    onClose();
  };

  return (
    <Flex as="li" alignItems="center">
      <Button
        label={t('header.services')}
        url={undefined}
        {...buttonWithArrowCommonStyles}
        width="100%"
        onClick={onToggle}
        variant="navbar"
        rightIcon={<Icon as={Arrow} fontSize={20} transition="transform 0.2s linear" transform="rotate(90deg)" />}
      />

      <Flex
        as="ul"
        display={isOpen ? 'flex' : 'none'}
        position="absolute"
        top="0"
        left="0"
        right="0"
        height={isOrientationLandscape ? '100vh' : '100%'}
        flexDirection="column"
        justifyContent="flex-start"
        gap={isOrientationLandscape ? 0 : 6}
        backgroundColor="beige.50"
        visibility={isOpen ? 'visible' : 'hidden'}
        zIndex="mobileMenuFirstNesting"
        aria-label={`${t('header.services')} submenu`}
        aria-hidden={!isOpen}
      >
        <Button
          color="violet.500"
          label={t('header.services')}
          {...buttonWithArrowCommonStyles}
          pb={6}
          width="100%"
          variant="navbar"
          onClick={onClose}
          leftIcon={
            <Icon as={Arrow} color="violet.500" mr={4} transform="rotate(-90deg)" transition="transform 0.2s linear" />
          }
        />
        <Flex gap={6} flexDirection="column">
          {servicesMenuItems.map((el) => (
            <ServicesMenuItem key={el.title} title={el.title} icon={el.icon} links={el.links} mainLink={el.mainLink} />
          ))}
        </Flex>
        <Button
          label={t('header.contact')}
          variant="light"
          size="lg"
          url={GeneralPath.CONTACT}
          w="100%"
          mb={isOrientationLandscape ? 4 : 0}
          onClick={handleCloseMenu}
        />
      </Flex>
    </Flex>
  );
};
