import { defineStyle, defineStyleConfig } from '@chakra-ui/react';

const variantLime = defineStyle({
  icon: {
    color: 'violet.700',
  },
  control: {
    background: 'white',
  },
});

const variants = {
  lime: variantLime,
};

export const checkboxTheme = defineStyleConfig({
  variants,
});
