import { IconButton, IconButtonProps, forwardRef } from '@chakra-ui/react';

export interface ShareButtonProps extends IconButtonProps {
  href: string;
}

export const ShareButton = forwardRef((props: ShareButtonProps, ref) => {
  return (
    <IconButton
      w="40px"
      h="40px"
      isRound
      colorScheme="violet"
      bg="transparent"
      variant="outline"
      borderColor="violet.200_alpha50"
      color="purple.400"
      fontSize="1.5rem"
      as="a"
      target="_blank"
      {...props}
      ref={ref}
    />
  );
});
