export const GoogleTagManagerNoscript = () => {
  const tagManagerId = process.env.NEXT_PUBLIC_TAG_MANAGER_ID;

  if (!tagManagerId) {
    return null;
  }

  return (
    <noscript
      dangerouslySetInnerHTML={{
        __html: `<iframe
        src="https://www.googletagmanager.com/ns.html?id=${tagManagerId}"
        height="0"
        width="0"
        style="display:none;visibility:hidden"></iframe>`,
      }}
    />
  );
};
