import { PropsWithChildren } from 'react';

import { Box, BoxProps } from '@chakra-ui/react';

export type SectionWrapperProps = PropsWithChildren & BoxProps;

export const SectionWrapper = (props: SectionWrapperProps) => {
  const { children, ...boxProps } = props;

  return (
    <Box as="section" position="relative" {...boxProps}>
      {children}
    </Box>
  );
};
