import { FooterColumn } from '../FirstColumnAndMobile/FirstColumnAndMobile';
import { LinksColumn } from '../LinksColumn';

type LastColumnsProps = {
  thirdColumnData?: FooterColumn | null;
  forthColumnData?: FooterColumn | null;
};

export const LastColumns = ({ thirdColumnData, forthColumnData }: LastColumnsProps) => {
  if (thirdColumnData && forthColumnData) {
    return (
      <>
        <LinksColumn linksColumnData={thirdColumnData} />
        <LinksColumn linksColumnData={forthColumnData} />
      </>
    );
  }
  return null;
};
