import { Grid, useMediaQuery, useTheme } from '@chakra-ui/react';

import { SecondColumn } from '@/components/layouts/Footer/SecondColumn';

import { FooterColumn, FooterIconsColumn } from '../FirstColumnAndMobile/FirstColumnAndMobile';
import { LastColumns } from '../LastColumns/LastColumns';

interface RemainingColumnsProps {
  secondColumnData: FooterColumn;
  followUsColumnData: FooterIconsColumn;
  thirdColumnData?: FooterColumn | null;
  forthColumnData?: FooterColumn | null;
}

export const RemainingColumns = ({
  secondColumnData,
  thirdColumnData,
  forthColumnData,
  followUsColumnData,
}: RemainingColumnsProps) => {
  const theme = useTheme();
  const [isLargerThanSm, isLargerThanMd] = useMediaQuery([
    `(min-width: ${theme.breakpoints.sm})`,
    `(min-width: ${theme.breakpoints.md})`,
  ]);

  if (!isLargerThanSm) {
    return <SecondColumn contactUsColumnData={secondColumnData} followUsColumnData={followUsColumnData} />;
  }

  return (
    <>
      <SecondColumn contactUsColumnData={secondColumnData} followUsColumnData={followUsColumnData}>
        {!isLargerThanMd ? (
          <Grid templateColumns="repeat(2, 1fr)">
            <LastColumns thirdColumnData={thirdColumnData} forthColumnData={forthColumnData} />
          </Grid>
        ) : null}
      </SecondColumn>
      {isLargerThanMd ? <LastColumns thirdColumnData={thirdColumnData} forthColumnData={forthColumnData} /> : null}
    </>
  );
};
