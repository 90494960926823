import { Box, Text, TextProps } from '@chakra-ui/react';

interface Props {
  label: string;
  onClick: (name: string) => void;
  selected?: boolean;
  scaleDown?: boolean;
  buttonTextStyle?: TextProps;
}

export const FilterButton = ({ label, selected, onClick, scaleDown, buttonTextStyle }: Props) => {
  return (
    <Box
      px={4}
      py={2}
      h={
        scaleDown
          ? {
              base: '34px',
              sm: '42px',
            }
          : '42px'
      }
      minW="fit-content"
      display="flex"
      justifyContent="center"
      alignItems="center"
      borderRadius="100px"
      userSelect="none"
      backgroundColor={selected ? 'violet.400' : 'violet.100'}
      cursor={selected ? 'initial' : 'pointer'}
      transition="all .3s"
      sx={{
        _hover: {
          backgroundColor: selected ? 'violet.400' : 'violet.200',
        },
      }}
      onClick={() => onClick(label)}
    >
      <Text
        color={selected ? 'white' : 'violet.700'}
        variant={
          scaleDown
            ? {
                base: 'text-xs/lineHeight-4/font-medium',
                sm: 'text-md/lineHeight-6/font-medium',
              }
            : 'text-md/lineHeight-6/font-medium'
        }
        fontSize={
          scaleDown
            ? {
                base: '12px',
                sm: '16px',
              }
            : '16px'
        }
        whiteSpace="nowrap"
        {...buttonTextStyle}
      >
        {label}
      </Text>
    </Box>
  );
};
