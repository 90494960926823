const breakpoints = {
  base: '0',
  '2xs': '20em', // 320px
  xs: '30em', // 480px
  sm: '48em', // 768px
  md: '57em', // 912px
  lg: '80em', // 1280px
  xl: '90em', // 1440px
  '2xl': '100em', // 1600px
  '3xl': '120em', // 1920px
};

export default breakpoints;
