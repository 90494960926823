/**
 * The model imitates and extends  Strapi's ComponentLayoutFooter.
 */
import { HTMLAttributeAnchorTarget } from 'react';

import EuFundsIcon from 'icons/eu-funds.png';
import EuropeFlagIcon from 'icons/europe-flag.png';
import LogoPolandIcon from 'icons/logo-poland.png';
import PolandFlagIcon from 'icons/republic-of-poland.png';
import { StaticImageData } from 'next/image';

import { ComponentCommonComponentsLink } from '@/graphql/generated/graphql';

import { GeneralPath } from './page-paths';

type Link = Omit<ComponentCommonComponentsLink, 'id'> & {
  target?: HTMLAttributeAnchorTarget;
  transKey?: string;
};

export type FooterBottomBar = {
  icons: {
    icons: {
      iconOne: StaticImageData;
      iconTwo: StaticImageData;
      iconThree: StaticImageData;
      iconFour: StaticImageData;
    };
  };
  links: {
    euProjects: Link;
    privacyPolicy: Link;
  };
};

export const footerBottomBar: FooterBottomBar = {
  icons: {
    icons: {
      iconOne: EuropeFlagIcon,
      iconTwo: PolandFlagIcon,
      iconThree: LogoPolandIcon,
      iconFour: EuFundsIcon,
    },
  },
  links: {
    euProjects: {
      label: 'EU Projects',
      transKey: 'footer.menu.euProjects',
      url: GeneralPath.EU_PROJECTS,
    },
    privacyPolicy: {
      label: 'Privacy policy',
      transKey: 'footer.menu.privacyPolicy',
      url: GeneralPath.PRIVACY_POLICY,
    },
  },
};
