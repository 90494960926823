import { useCallback, useEffect, useRef, useState } from 'react';

import { HEADER_HEIGHT } from '@/utils/constants';

// For the function to work, the target must have empty data-section and filled id attributes specified

export const useActiveSection = () => {
  const [activeSection, setActiveSection] = useState<string | null>(null);
  const sections = useRef<HTMLElement[]>([]);

  const handleScroll = useCallback(() => {
    let newActiveSection = null;

    sections.current.forEach((section) => {
      const sectionOffsetTop = section.offsetTop;

      if (sectionOffsetTop - document.documentElement.scrollTop - parseInt(HEADER_HEIGHT) <= 0) {
        newActiveSection = section.id;
      }
    });

    setActiveSection(newActiveSection);
  }, [setActiveSection]);

  useEffect(() => {
    sections.current = Array.from(document.querySelectorAll<HTMLLIElement>('[data-section]'));

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [handleScroll]);

  return activeSection;
};
