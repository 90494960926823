import { ReactElement } from 'react';

import { Box, GridItem, Heading } from '@chakra-ui/react';

import { FooterColumn, FooterIconsColumn } from '../FirstColumnAndMobile/FirstColumnAndMobile';
import { ContactLinks } from './ContactLinks';
import { SocialMediaItems } from './SocialMediaItems';

interface SecondColumnProps {
  contactUsColumnData: FooterColumn;
  followUsColumnData: FooterIconsColumn;
  children?: ReactElement | null;
}

export const SecondColumn = ({ contactUsColumnData, followUsColumnData, children }: SecondColumnProps) => {
  return (
    <GridItem gridColumn={{ base: '1/-1', sm: '2/3' }} mt={{ base: 4, md: 0 }}>
      {children}
      <Box mt={{ base: 8, md: 0 }}>
        <Heading as="h3" variant="text-xs/lineHeight-4/font-medium/0.75rem" color="purple.400">
          {contactUsColumnData.heading}
        </Heading>
        <ContactLinks contactLinks={contactUsColumnData.links} />
        <Box mt={6}>
          <Heading as="h3" color="purple.400" variant="text-xs/lineHeight-4/font-medium/0.75rem">
            {followUsColumnData.heading}
          </Heading>
          <SocialMediaItems socialItems={followUsColumnData.links} />
        </Box>
      </Box>
    </GridItem>
  );
};
