import { useEffect, useRef } from 'react';

export const useBodyScrollBlock = (shouldBlock: boolean) => {
  const body = useRef<HTMLBodyElement | null>();

  useEffect(() => {
    if (window) {
      body.current = document.querySelector('body');
    }
  }, []);

  useEffect(() => {
    if (body.current) {
      if (shouldBlock) {
        body.current.style.overflow = 'hidden';
      } else {
        body.current.style.overflow = 'initial';
      }
    }
  }, [shouldBlock, body]);
};
