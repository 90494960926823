import { useRouter } from 'next/router';

import { DEFAULT_LOCALE } from '@/utils/constants';

export const useLocale = () => {
  const router = useRouter();
  const locale = router.locale || DEFAULT_LOCALE;

  return locale;
};
