import { TypedDocumentNode } from '@graphql-typed-document-node/core';
import { QueryFunctionContext } from '@tanstack/react-query';
import { GraphQLClient, RequestDocument, RequestOptions, Variables } from 'graphql-request';

const graphqlClient = new GraphQLClient(process.env.NEXT_PUBLIC_STRAPI_GRAPHQL_URL as string);

export function fetcher<TData, TVariables extends Variables>(
  document: RequestDocument | TypedDocumentNode<TData, Variables>,
  variables?: TVariables,
  requestHeaders?: RequestInit['headers'],
) {
  return async (context?: QueryFunctionContext): Promise<TData> =>
    graphqlClient.request({
      document,
      variables,
      requestHeaders,
      signal: context?.signal,
    } as unknown as RequestOptions<TVariables, TData>);
}
