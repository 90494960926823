import { FC } from 'react';

import { Box, Flex, Text } from '@chakra-ui/react';
import Link from 'next/link';

import { usePathUtils } from '@/hooks';
import { CONTENT_MARGIN_X, CONTENT_MAX_WIDTH_PX } from '@/utils/constants';
import { GeneralPath, breadcrumbsLabels } from '@/utils/models';

interface Props {
  isAbsolute?: boolean;
  lightMode?: boolean;
}

export const Breadcrumbs: FC<Props> = ({ isAbsolute, lightMode }) => {
  const {
    pathSegments,
    isArticlePage,
    isHomePage,
    isDesignPage,
    isAboutUsPage,
    isServicePage,
    isContactPage,
    parseSegment,
  } = usePathUtils();
  const altColor = isAboutUsPage || isDesignPage || lightMode;
  const positionAbsolute = isAboutUsPage || isDesignPage || isHomePage || isContactPage || isAbsolute;
  const elementColor = altColor ? 'purple.200' : 'purple.300';
  const lastElementColor = altColor ? 'white' : 'violet.500';

  const paths = [
    {
      url: '/',
      label: 'Homepage',
    },
    ...pathSegments.map((segment, index) => {
      const urlSegments = pathSegments.slice(0, index + 1);
      const url = isArticlePage ? GeneralPath.BLOG : isServicePage ? '' : `/${urlSegments.join('/')}`;
      return {
        url,
        label: breadcrumbsLabels[segment] || parseSegment(segment),
      };
    }),
  ];

  return paths.length > 1 ? (
    <Flex w="full" px={CONTENT_MARGIN_X} justifyContent="center">
      <Box
        as="nav"
        maxW={CONTENT_MAX_WIDTH_PX}
        position={positionAbsolute ? 'absolute' : 'relative'}
        zIndex="2"
        mt={CONTENT_MARGIN_X}
        px={positionAbsolute ? CONTENT_MARGIN_X : 0}
        width="full"
      >
        {paths.map((path, index) => {
          const isLastElement = paths.length === index + 1;

          return (
            <Text as="span" key={index}>
              {index !== 0 && <Text as="span" color={elementColor}>{` - `}</Text>}
              <Link href={path.url}>
                <Text
                  as="span"
                  color={isLastElement ? lastElementColor : elementColor}
                  fontSize="text-sm"
                  fontWeight="400"
                  _hover={{
                    textDecor: 'underline',
                  }}
                >
                  {path.label}
                </Text>
              </Link>
            </Text>
          );
        })}
      </Box>
    </Flex>
  ) : null;
};
