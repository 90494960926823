import { ChangeEvent, Dispatch, SetStateAction, useCallback, useEffect, useMemo } from 'react';

import { Input, InputGroup, InputLeftElement } from '@chakra-ui/react';
import Search from 'images/icons/search.svg';
import debounce from 'lodash.debounce';
import { useTranslation } from 'next-i18next';

import { LOCALES } from '@/utils/constants';

interface SearchInputProps {
  setInputValue: Dispatch<SetStateAction<string>>;
}

const DEBOUNCE_WAIT_TIME = 500;

export const SearchInput = ({ setInputValue }: SearchInputProps) => {
  const { t } = useTranslation(LOCALES.COMMON);

  const handleInputChange = useCallback(
    (evt: ChangeEvent<HTMLInputElement>) => {
      const target = evt.target.value.toLowerCase();
      setInputValue(target);
    },
    [setInputValue],
  );

  const debouncedHandler = useMemo(() => debounce(handleInputChange, DEBOUNCE_WAIT_TIME), [handleInputChange]);

  useEffect(() => {
    return () => debouncedHandler.cancel();
  }, [debouncedHandler]);

  return (
    <InputGroup mt={{ base: 14, md: 0 }} flexBasis={{ md: '302px' }}>
      <InputLeftElement ml={4} justifyContent="initial" w="17px">
        <Search />
      </InputLeftElement>
      <Input pl={12} placeholder={t('search')} onChange={debouncedHandler} backgroundColor="white" />
    </InputGroup>
  );
};
