import { Flex, Link, Text } from '@chakra-ui/react';
import NextLink from 'next/link';

import { EXIT_PREVIEW_PATH } from '@/utils/constants';

export const PreviewModeBanner = () => {
  return (
    <Flex width="100%" padding="10px 20px" justifyContent="space-between" color="#FFFFFF" backgroundColor="#FF0000">
      <Text>Preview mode is on</Text>
      <Link as={NextLink} href={EXIT_PREVIEW_PATH}>
        <Text>&gt;&gt; Exit preview mode &lt;&lt;</Text>
      </Link>
    </Flex>
  );
};
