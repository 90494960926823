import React from 'react';

export const LinkedinPixelNoScript = () => {
  return (
    <noscript>
      <img
        height="1"
        width="1"
        style={{ display: 'none' }}
        alt=""
        src="https://px.ads.linkedin.com/collect/?pid=458204&fmt=gif"
      />
    </noscript>
  );
};
