import { Flex, HStack } from '@chakra-ui/react';

import { NavbarItem } from '@/utils/models';

import { DropdownConsultationSection } from './DropdownConsultationSection';
import { DropdownItems } from './DropdownItems';

interface DropdownProps {
  label: string;
  subItems?: NavbarItem[];
  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
  dropdownArrowLeft?: string;
}

export const Dropdown = ({ label, subItems, isOpen, onOpen, onClose, dropdownArrowLeft }: DropdownProps) => {
  return (
    <Flex
      width={{ md: '740px', lg: '840px' }}
      position="absolute"
      left="-44px"
      pt={6}
      _after={{
        content: '""',
        position: 'absolute',
        left: ['140px', '140px', '140px', dropdownArrowLeft || '64px'],
        top: '14px',
        height: '36px',
        width: '36px',
        backgroundColor: 'white',
        borderRadius: '6px',
        transform: 'rotate(45deg)',
      }}
      display={isOpen ? 'flex' : 'none'}
      onMouseEnter={onOpen}
      onMouseLeave={onClose}
    >
      <HStack bg="white" borderRadius="24px" w="full">
        <DropdownItems label={label} subItems={subItems} isOpen={isOpen} onClose={onClose} />
        <DropdownConsultationSection onClose={onClose} />
      </HStack>
    </Flex>
  );
};
