import React, { FC } from 'react';

import { Button } from '@/components/atoms';

import { HamburgerIcon } from './MobileHeaderContent';

type HeaderMobileButtonProps = {
  isOpen: boolean;
  onToggle: () => void;
};

export const HeaderMobileButton: FC<HeaderMobileButtonProps> = ({ isOpen, onToggle }) => {
  return (
    <Button
      leftIcon={<HamburgerIcon isMenuOpen={isOpen} />}
      onClick={onToggle}
      variant="navbar"
      minW="30px"
      w="30px"
      h="30px"
      aria-label="Open menu"
    />
  );
};
