import { Box, GridItem, Icon, Text } from '@chakra-ui/react';
import Logo from 'images/icons/sdh-logo-mobile.svg';
import { useTranslation } from 'next-i18next';

import { MobileFooterMenu } from '@/components/layouts/Footer/MobileFooterMenu';
import { LOCALES } from '@/utils/constants';

export type FooterColumn = {
  heading: string;
  links: {
    label: string;
    url: string;
  }[];
};
export type FooterIconsColumn = Pick<FooterColumn, 'heading'> & {
  links: {
    icon: string;
    url: string;
  }[];
};

interface FirstColumnProps {
  thirdColumnData?: FooterColumn | null;
  forthColumnData?: FooterColumn | null;
}

export const FirstColumnAndMobile = ({ thirdColumnData, forthColumnData }: FirstColumnProps) => {
  const { t } = useTranslation(LOCALES.COMMON);

  return (
    <GridItem gridColumn={{ base: '1/-1', sm: '1/2' }}>
      <MobileFooterMenu thirdColumnData={thirdColumnData} forthColumnData={forthColumnData} />
      <Box width="51px" height="24px">
        <Icon as={Logo} w="100%" h="100%" />
      </Box>

      <Box>
        <Box mt={10} color="purple.500">
          <Text variant="text-md/lineHeight-6/font-medium">{t('footer.company.0')}</Text>
          <Text variant="text-md/lineHeight-6/font-medium">{t('footer.company.1')}</Text>
          <Text variant="text-md/lineHeight-6/font-medium">{t('footer.company.2')}</Text>
        </Box>
        <Box mt={6} color="purple.400">
          <Text variant="text-md/lineHeight-6/font-medium">{t('footer.company.3')}</Text>
          <Text variant="text-md/lineHeight-6/font-medium">{t('footer.company.4')}</Text>
          <Text variant="text-md/lineHeight-6/font-medium">{t('footer.company.5')}</Text>
        </Box>
      </Box>
    </GridItem>
  );
};
