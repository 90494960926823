import { MouseEvent } from 'react';

import { DECREASE_SCROLL_TOP_VALUE, HEADER_HEIGHT } from '@/utils/constants';

// For the function to work, the target must have data-element attribute specified
// Additionally, the corresponding element in DOM that corresponds to the target
// must have an id with the same value as the one specified in data-element

export const useScrollOnClick = (topAdjustment: boolean = true) => {
  const handleScrollToElement = (evt: MouseEvent<HTMLElement>) => {
    const target = evt.target as HTMLElement;
    const targetElement = target.dataset.element as string;
    const clickedElementInDOM: HTMLElement | null = document.getElementById(targetElement);
    let top = 0;
    if (clickedElementInDOM) {
      if ('getBoundingClientRect' in clickedElementInDOM) {
        top = clickedElementInDOM.getBoundingClientRect().top;
      } else {
        top = (clickedElementInDOM as HTMLElement).offsetHeight;
      }
    }
    const scrollTopValue =
      top + window.scrollY - parseInt(HEADER_HEIGHT) - (topAdjustment ? DECREASE_SCROLL_TOP_VALUE : 0);
    window.scrollTo({
      top: scrollTopValue,
      behavior: 'smooth',
    });
  };

  return handleScrollToElement;
};
