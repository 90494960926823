/* GENERAL ROUTES */
export enum GeneralPath {
  ABOUT_US = '/about-us',
  BLOG = '/blog',
  CASE_STUDIES = '/case-studies',
  HOME = '/',
  GLOSSARY = '/glossary',
  LIBRARY = '/library',
  SERVICES = '/services',
  GLOSSARY_ALL = '/glossary',
  PRIVACY_POLICY = '/privacy-policy',
  EU_PROJECTS = '/eu-projects',
  CONTACT = '/contact',
  THANKS = '/thanks',
  DYNAMIC_PAGES = '/pages',
}

/* BLOG ROUTES */
export enum BlogPath {
  ARTICLE = '/blog/:slug',
}

/* CASE STUDIES ROUTES */
export enum CaseStudiesPath {
  CASE_STUDY = '/case-studies/:slug',
}

/* GLOSSARY ROUTES */
export enum GlossaryPath {
  GLOSSARY_DEFINITION = '/glossary/:slug',
}

/* LIBRARY ROUTES */
export enum LibraryPath {
  LIBRARY = '/library/:slug',
}

/* SERVICES ROUTES */
export enum ServicesPath {
  DESIGN = '/pages/design',
  DEVELOPMENT = '/pages/development',
  MVP_BUILDING = '/pages/mvp-building',
  NO_CODE = '/pages/no-code',
  PRODUCT_DISCOVERY = '/pages/product-discovery',
}

export enum IndustriesPath {
  HOME = '/pages/industries',
  HEALTHCARE = '/pages/healthcare-industry',
  EDTECH = '/pages/edtech-industry',
  AI = '/pages/ai-industry',
  FINTECH = '/pages/fintech-industry',
  TRAVEL = '/pages/travel-industry',
}

/* HELPERS */
type Path = BlogPath.ARTICLE | CaseStudiesPath.CASE_STUDY | GlossaryPath.GLOSSARY_DEFINITION | LibraryPath.LIBRARY;

export function getDynamicPath(path: Path, slug: string): string {
  return path.replace(':slug', slug);
}

export const pathsToChangeDefaultLayoutOverflow = [
  GeneralPath.PRIVACY_POLICY,
  GeneralPath.ABOUT_US,
  ServicesPath.DEVELOPMENT,
];
