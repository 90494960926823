import { useMemo } from 'react';

import { useRouter } from 'next/router';

export const useShareUrls = () => {
  const router = useRouter();

  const origin = typeof window !== 'undefined' && window.location.origin ? window.location.origin : '';
  const websiteUrl = process.env.NEXT_PUBLIC_WEBSITE_URL || origin;
  const currentUrl = websiteUrl + router.asPath.replace(/#.*/g, '');

  return useMemo(() => {
    const facebookUrl = new URL('https://www.facebook.com/sharer/sharer.php');
    facebookUrl.searchParams.set('u', currentUrl);

    const twitterUrl = new URL('https://twitter.com/share');
    twitterUrl.searchParams.set('url', currentUrl);
    // TODO: we can add some text or hashtags here
    //   twitterUrl.searchParams.set('text', 'some text');
    //   twitterUrl.searchParams.set('hashtags', 'tag1,tag2');

    const linkedInUrl = new URL('https://www.linkedin.com/shareArticle');
    linkedInUrl.searchParams.set('url', currentUrl);

    return {
      facebook: facebookUrl.toString(),
      twitter: twitterUrl.toString(),
      linkedIn: linkedInUrl.toString(),
    };
  }, [currentUrl]);
};
