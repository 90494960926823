import { accordionAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(accordionAnatomy.keys);

const baseStyle = definePartsStyle({
  container: {
    paddingBottom: {
      base: 6,
      md: 12,
    },
    paddingTop: {
      base: 6,
      md: 12,
    },
    '&:first-of-type': {
      borderTop: 'none',
    },
    '&:last-of-type': {
      borderBottom: 'none',
    },
    '& > div:first-of-type': {
      display: 'flex',
      justifyContent: 'space-between',
      paddingX: 0,
      height: '100%',
      cursor: 'pointer',
      _hover: {
        background: 'initial',
      },
    },
  },
  panel: {
    paddingX: 0,
    paddingBottom: 0,
    paddingTop: {
      base: 2,
      md: 6,
    },
  },
});

export const accordionTheme = defineMultiStyleConfig({ baseStyle });
