import { useEffect, useState } from 'react';

import { Show, VisuallyHidden } from '@chakra-ui/react';

import { PRELOADED_IMAGES, PRELOADED_IMAGES_DELAY } from '@/utils/constants';

export const PreloadedImages = () => {
  const [preloadDelay, setPreloadDelay] = useState<boolean>(true);

  useEffect(() => {
    const timer = setTimeout(() => setPreloadDelay(false), PRELOADED_IMAGES_DELAY);
    return () => {
      clearTimeout(timer);
    };
  }, []);

  if (preloadDelay) return null;

  return (
    <Show above="md">
      <VisuallyHidden>
        {PRELOADED_IMAGES.map((imageUrl) => (
          // eslint-disable-next-line @next/next/no-img-element
          <img key={imageUrl} src={imageUrl} alt="preloaded" height="124px" width="124px" />
        ))}
      </VisuallyHidden>
    </Show>
  );
};
