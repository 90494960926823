import { useRouter } from 'next/router';
import Script from 'next/script';

import { GeneralPath, ServicesPath } from '../models';

const ROUTES_TO_OBSERVE = [
  GeneralPath.HOME,
  ServicesPath.PRODUCT_DISCOVERY,
  ServicesPath.DESIGN,
  ServicesPath.DEVELOPMENT,
  ServicesPath.MVP_BUILDING,
  ServicesPath.NO_CODE,
  GeneralPath.ABOUT_US,
  GeneralPath.CASE_STUDIES,
  GeneralPath.CASE_STUDIES + '/[slug]',
];

export const HotjarScript = () => {
  const { pathname } = useRouter();
  const HOTJAR_ID = process.env.NEXT_PUBLIC_HOTJAR_ID;

  if (!HOTJAR_ID || !ROUTES_TO_OBSERVE.includes(pathname)) {
    return null;
  }

  return (
    <Script
      dangerouslySetInnerHTML={{
        __html: `(function(h,o,t,j,a,r){
        h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
        h._hjSettings={hjid:${HOTJAR_ID},hjsv:6};
        a=o.getElementsByTagName('head')[0];
        r=o.createElement('script');r.async=1;
        r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
        a.appendChild(r);
    })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');`,
      }}
      id="hotjar"
      strategy="lazyOnload"
    />
  );
};
