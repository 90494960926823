import { Flex, Text } from '@chakra-ui/react';
import Link from 'next/link';

import { LinkFragment, Maybe } from '@/graphql/generated';
import { regex } from '@/utils/regex';

enum ContactLinkType {
  PHONE_NUMBER,
  EMAIL,
  OTHER,
}

interface ContactLinksProps {
  contactLinks: Maybe<LinkFragment>[];
}

export const ContactLinks = ({ contactLinks }: ContactLinksProps) => {
  const getContactLinkType = (contactLink: Maybe<LinkFragment>): ContactLinkType => {
    if (regex.email.test(contactLink?.url ?? '')) {
      return ContactLinkType.EMAIL;
    }

    if (regex.phoneNumber.test(contactLink?.url ?? '')) {
      return ContactLinkType.PHONE_NUMBER;
    }

    return ContactLinkType.OTHER;
  };

  const getValidURL = (url: string = '', contactLinkType: ContactLinkType) => {
    switch (contactLinkType) {
      case ContactLinkType.PHONE_NUMBER:
        return `tel:${url}`;
      case ContactLinkType.EMAIL:
        return `mailto:${url}`;
      case ContactLinkType.OTHER:
        return url;
    }
  };

  return (
    <Flex flexDirection="column" mt={2} fontSize="text-md" lineHeight="lineHeight-6" color="purple.500">
      {contactLinks?.map((link) => {
        if (link) {
          const { label, url } = link;
          return (
            <Link key={label} href={getValidURL(url, getContactLinkType(link))}>
              <Text variant="text-md/lineHeight-6/font-medium">{label}</Text>
            </Link>
          );
        }
      })}
    </Flex>
  );
};
