import { useEffect, useState } from 'react';

import { useScroll } from 'framer-motion';

type UseScrollProgressProps = HTMLDivElement | null;

export const useScrollProgress = (containerRef: UseScrollProgressProps) => {
  const [scrollPercentage, setScrollPercentage] = useState(0);
  const [containerHeight, setContainerHeight] = useState(0);
  const { scrollY } = useScroll();

  useEffect(() => {
    if (containerRef) {
      setContainerHeight(containerRef.offsetHeight);
    }
  }, [containerRef]);

  const onScroll = (scrollPosition: number) => {
    const scrollPercentage = scrollPosition / containerHeight;
    setScrollPercentage(scrollPercentage < 100 ? scrollPercentage : 100);
  };

  scrollY.on('change', (scrollPosition) => onScroll(scrollPosition));

  return scrollPercentage;
};
