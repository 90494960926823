import { Box, Grid } from '@chakra-ui/layout';
import { useMediaQuery, useTheme } from '@chakra-ui/react';
import { useTranslation } from 'next-i18next';

import { StaticBackground } from '@/components/atoms/StaticBackground';
import { SectionContentWrapper } from '@/components/wrappers';
import { LOCALES } from '@/utils/constants';
import { footerBottomBar } from '@/utils/models';
import { useFooterData } from '@/utils/models/use-footer-data';

import { BottomBar } from './BottomBar';
import { FirstColumnAndMobile } from './FirstColumnAndMobile/FirstColumnAndMobile';
import { RemainingColumns } from './RemainingColumns';

export const Footer = () => {
  const { t } = useTranslation(LOCALES.COMMON);
  const theme = useTheme();
  const [isSmallerThanSm] = useMediaQuery(`(max-width: ${theme.breakpoints.sm})`);

  const { columnCompany, columnServices, columnFollowUs, columnContactUs } = useFooterData();
  const copyright = `${t('footer.copyright.0')} ${new Date().getFullYear()} ${t('footer.copyright.1')}`;

  return (
    <Grid as="footer" templateColumns="1fr" position="relative">
      <Box as={SectionContentWrapper} w="100%" {...(isSmallerThanSm ? { pt: '0' } : {})}>
        <StaticBackground left="-45%" top="-30%" backgroundColor="violet.50" width="1000px" height="1000px" />
        <Grid
          templateColumns={{ base: 'repeat(2, 1fr)', md: 'repeat(4, 1fr)' }}
          justifyItems={{ base: 'left', sm: 'center' }}
          alignItems="baseline"
          textAlign="left"
          gap={3}
        >
          <FirstColumnAndMobile thirdColumnData={columnCompany} forthColumnData={columnServices} />
          <RemainingColumns
            followUsColumnData={columnFollowUs}
            secondColumnData={columnContactUs}
            thirdColumnData={columnCompany}
            forthColumnData={columnServices}
          />
        </Grid>
      </Box>
      <BottomBar bottomBarData={footerBottomBar} copyright={copyright} />
    </Grid>
  );
};
