export const isMenuActive = (route: string, href: string) => {
  return route.startsWith(href);
};

export const navbarItemUnderlineStyles = {
  content: '""',
  position: 'absolute',
  width: '100%',
  height: '1px',
  bottom: '2px',
  left: '0',
  backgroundColor: 'violet.500',
  transformOrigin: 'bottom left',
  transition: 'transform 0.25s ease-out',
};

export const getNavbarItemStyles = (isActive: boolean, isMobile: boolean | undefined) => ({
  display: 'inline-flex',
  position: 'relative' as 'relative',
  alignItems: 'center',
  textDecoration: 'none',
  fontWeight: 'medium',
  fontSize: isMobile ? 20 : { base: 'text-md', '2xl': 'text-lg' },
  lineHeight: isMobile ? 7 : 6,
});
