import { defineStyle, defineStyleConfig } from '@chakra-ui/react';

import { typographyVariants } from './typography-variants';

const articleVariant = defineStyle((props) => {
  const { size } = props;

  const borderColor = size === 'xl' ? 'violet.200_alpha50' : 'violet.200';
  const border = size === 'xl' ? '2px' : '1px';

  return {
    container: {
      borderRadius: 'full',
      border,
      borderColor,
      color: 'purple.400',
    },
  };
});

const articleFeaturedVariant = defineStyle((props) => {
  return {
    container: {
      ...articleVariant(props).container,
      background: 'red.100',
      borderColor: 'red.100',
    },
  };
});

export const tagTheme = defineStyleConfig({
  variants: {
    article: articleVariant,
    articleFeatured: articleFeaturedVariant,
  },
  sizes: {
    xl: {
      container: {
        ...typographyVariants['text-sm/lineHeight-5/font-medium'],
        px: 3.5,
        h: 10,
      },
    },
    md: {
      container: {
        ...typographyVariants['text-xs/lineHeight-4/font-medium/letterSpacing-wide'],
      },
    },
  },
});
