import { GeneralPath } from '@/utils/models';

import { Alphabet } from './models/inventory-items';

export const EXIT_PREVIEW_PATH = '/api/exit-preview';
export const MAIL_PATH = '/api/send-email';
export const SIGNED_URL = (filename: string) => `/api/signed-bucket-url?file=${filename}`;

export const CALENDLY_URL = 'https://calendly.com/d/dv3-tfw-97c/discovery-call-with-startup-house';

export const CONTENT_MAX_WIDTH = 1920;
export const CONTENT_MAX_WIDTH_PX = CONTENT_MAX_WIDTH + 'px';
export const CONTENT_MARGIN_X = 5;

export const TILE_WIDTH = 217;
export const TILE_WIDTH_PX = TILE_WIDTH + 'px';

export const EXTERNAL_URL = {
  CAREERS: 'https://careers.startup-house.com/',
  CAREERS_PARTNER: 'https://careers.startup-house.com/pages/become-a-partner',
  TEL_OFFICE: 'tel:+48 789 011 336',
  TEL_BUSINESS: 'tel:+48 798 874 852',
  EMAIL: 'hello@start-up.house',
  INSTAGRAM: 'https://www.instagram.com/startuphousecom/',
  FACEBOOK: 'https://www.facebook.com/startuphousecom/',
  LINKEDIN: 'https://www.linkedin.com/company/startup-house-com',
  DRIBBBLE: 'https://dribbble.com/startup-house',
  GITHUB: 'https://github.com/startupdevhouse',
  TWITTER: 'https://twitter.com/startuphousecom',
};

export const HEADER_HEIGHT = '80px';
export const HIGHLIGHT_JS_URL = 'https://cdn.jsdelivr.net/gh/highlightjs/cdn-release@11.7.0/build/highlight.min.js';
export const HIGHLIGHT_JS_STYLE_URL =
  'https://cdn.jsdelivr.net/gh/highlightjs/cdn-release@11.7.0/build/styles/brown-paper.min.css';

export const CLUTCH_WIDGET_URL = 'https://widget.clutch.co';
export const CLUTCH_SCRIPT_URL = 'https://widget.clutch.co/static/js/widget.js';
export const CLUTCH_COMPANY_ID = '68037';

export const COOKIEYES_URL = 'https://cdn-cookieyes.com/client_data/6606ec9a9c939f70e9b34059/script.js';
export const COOKIEYES_TABLE_TAG = 'cky-audit-table-element';
export const COOKIEYES_BANNER_TAG = 'cky-banner-element';
export const COOKIEYES_SHOW_BTN_CLASS = 'show-cookieyes-btn';
export const PATHS_TO_SHOW_COOKIEYES_BUTTON = [GeneralPath.PRIVACY_POLICY];
export const INLINKS_SCRIPT_URL = 'https://jscloud.net/lzs/20258/inlinks.js';

export const COMPANY_FOUNDATION_YEAR = 2016;

export const UTM_SOURCE_LS_KEY = 'UTM_SOURCE';

// in order not to slow down page initial load we delay preloaded images download
export const PRELOADED_IMAGES_DELAY = 2000;
export const PRELOADED_IMAGES = [
  '/assets/images/doodles/eye/1.svg',
  '/assets/images/doodles/eye/2.svg',
  '/assets/images/doodles/eye/3.svg',
];
export const DECREASE_SCROLL_TOP_VALUE = 40;
export const ALPHABET_LETTERS = 'ABCDEFGHIJKLMNOPQRSTUVWXZ'.split('');
export const ALPHABET: Alphabet[] = ALPHABET_LETTERS.map((letter) => {
  return {
    letter,
    titles: [],
  };
});

export const REGEX = {
  UNDERLINE_TEXT: /##(.*?)##/g,
  HIGHLIGHT_TEXT: /\*\*(.*?)\*\*/g,
  CIRCLED_TEXT: /\[(.*?)\]/g,
  EXCLAIMED_TEXT: /!!(.*?)!!/g,
};

export const PAGE_REVALIDATE_TIME = 60; // 60 seconds
export const PAGE_REVALIDATE_TIME_DYNAMIC_PAGES = 1;

export const LOCALE = {
  EN: 'en',
  PL: 'pl',
} as const;

export const DEFAULT_LOCALE: string = LOCALE.EN;

export enum LOCALES {
  ABOUT_US = 'about-us',
  ARTICLES = 'articles',
  CASE_STUDY = 'case-study',
  CONTACT = 'contact',
  COMMON = 'common',
  EU_PROJECTS = 'eu-projects',
  HOME = 'home',
  INVENTORY = 'inventory',
  SDH_LABS = 'sdh-labs',
  THANKS = 'thanks',
}

export type AvailableLanguages = (typeof LOCALE)[keyof typeof LOCALE];

export const OG_IMAGE = {
  WIDTH: 1200,
  HEIGHT: 630,
};

export const DEFAULT_OG_IMAGE = {
  data: {
    attributes: { url: 'https://storage.googleapis.com/startup-house-cms-staging/frame-mWvmSmoYq0.jpg' },
  },
};
