import colors from '../foundations/colors';

export const typographyVariants = {
  'text-xs/lineHeight-4/font-book/0.75rem': {
    fontFamily: 'body',
    fontWeight: 'book',
    fontSize: 'text-xs',
    lineHeight: 'lineHeight-4',
  },
  'text-xs/lineHeight-4/font-normal/0.75rem': {
    fontFamily: 'body',
    fontWeight: 'normal',
    fontSize: 'text-xs',
    lineHeight: 'lineHeight-4',
  },
  'text-xs/lineHeight-4/font-medium/0.75rem': {
    fontFamily: 'body',
    fontWeight: 'medium',
    fontSize: 'text-xs',
    lineHeight: 'lineHeight-4',
  },
  'text-xs/lineHeight-4/font-semibold/0.75rem': {
    fontFamily: 'body',
    fontWeight: 'semibold',
    fontSize: 'text-xs',
    lineHeight: 'lineHeight-4',
  },
  'text-xs/lineHeight-4/font-black/0.75rem': {
    fontFamily: 'body',
    fontWeight: 'black',
    fontSize: 'text-xs',
    lineHeight: 'lineHeight-4',
  },
  'text-xs/lineHeight-4/font-medium/letterSpacing-wide': {
    fontFamily: 'body',
    fontWeight: 'medium',
    fontSize: 'text-xs',
    lineHeight: 'lineHeight-3',
    letterSpacing: '2.5%',
  },
  'text-xs/lineHeight-4/font-medium/letterSpacing-wider/0.75rem': {
    fontFamily: 'body',
    fontWeight: 'medium',
    fontSize: 'text-xs',
    lineHeight: 'lineHeight-4',
    letterSpacing: 'letterSpacing-wider',
  },
  'text-xs/lineHeight-4/font-semibold/letterSpacing-wide': {
    fontFamily: 'body',
    fontWeight: 'semibold',
    fontSize: 'text-xs',
    lineHeight: 'lineHeight-3',
    letterSpacing: '2.5%',
  },
  'text-xs/lineHeight-4/font-semibold/letterSpacing-wider/0.75rem': {
    fontFamily: 'body',
    fontWeight: 'semibold',
    fontSize: 'text-xs',
    lineHeight: 'lineHeight-4',
    letterSpacing: 'letterSpacing-wider',
  },
  'text-xs/lineHeight-4/font-bold/letterSpacing-wider/0.75rem': {
    fontFamily: 'body',
    fontWeight: 'bold',
    fontSize: 'text-xs',
    lineHeight: 'lineHeight-4',
    letterSpacing: 'letterSpacing-wider',
  },
  'text-sm/lineHeight-5/font-book': {
    fontFamily: 'body',
    fontWeight: 'book',
    fontSize: 'text-sm',
    lineHeight: 'lineHeight-5',
  },
  'text-sm/lineHeight-5/font-normal': {
    fontFamily: 'body',
    fontWeight: 'normal',
    fontSize: 'text-sm',
    lineHeight: 'lineHeight-5',
  },
  'text-sm/lineHeight-5/font-medium': {
    fontFamily: 'body',
    fontWeight: 'medium',
    fontSize: 'text-sm',
    lineHeight: 'lineHeight-5',
  },
  'text-sm/lineHeight-5/font-semibold': {
    fontFamily: 'body',
    fontWeight: 'semibold',
    fontSize: 'text-sm',
    lineHeight: 'lineHeight-5',
  },
  'text-sm/lineHeight-5/font-bold': {
    fontFamily: 'body',
    fontWeight: 'bold',
    fontSize: 'text-sm',
    lineHeight: 'lineHeight-5',
  },
  'text-sm/lineHeight-5/font-black': {
    fontFamily: 'body',
    fontWeight: 'black',
    fontSize: 'text-sm',
    lineHeight: 'lineHeight-5',
  },
  'text-md/lineHeight-6/font-book': {
    fontFamily: 'body',
    fontWeight: 'book',
    fontSize: 'text-md',
    lineHeight: 'lineHeight-6',
  },
  'text-md/lineHeight-6/font-normal': {
    fontFamily: 'body',
    fontWeight: 'normal',
    fontSize: 'text-md',
    lineHeight: 'lineHeight-6',
  },
  'text-md/lineHeight-6/font-medium': {
    fontFamily: 'body',
    fontWeight: 'medium',
    fontSize: 'text-md',
    lineHeight: 'lineHeight-6',
  },
  'text-md/lineHeight-6/font-semibold': {
    fontFamily: 'body',
    fontWeight: 'semibold',
    fontSize: 'text-md',
    lineHeight: 'lineHeight-6',
  },
  'text-md/lineHeight-6/font-bold': {
    fontFamily: 'body',
    fontWeight: 'bold',
    fontSize: 'text-md',
    lineHeight: 'lineHeight-6',
  },
  'text-md/lineHeight-6/font-black': {
    fontFamily: 'body',
    fontWeight: 'black',
    fontSize: 'text-md',
    lineHeight: 'lineHeight-6',
  },
  'text-lg/lineHeight-7/font-book': {
    fontFamily: 'body',
    fontWeight: 'book',
    fontSize: 'text-lg',
    lineHeight: 'lineHeight-7',
  },
  'text-lg/lineHeight-7/font-normal': {
    fontFamily: 'body',
    fontWeight: 'normal',
    fontSize: 'text-lg',
    lineHeight: 'lineHeight-7',
  },
  'text-lg/lineHeight-7/font-medium': {
    fontFamily: 'body',
    fontWeight: 'medium',
    fontSize: 'text-lg',
    lineHeight: 'lineHeight-7',
  },
  'text-lg/lineHeight-7/font-semibold': {
    fontFamily: 'body',
    fontWeight: 'semibold',
    fontSize: 'text-lg',
    lineHeight: 'lineHeight-7',
  },
  'text-lg/lineHeight-7/font-bold': {
    fontFamily: 'body',
    fontWeight: 'bold',
    fontSize: 'text-lg',
    lineHeight: 'lineHeight-7',
  },
  'text-lg/lineHeight-7/font-black': {
    fontFamily: 'body',
    fontWeight: 'black',
    fontSize: 'text-lg',
    lineHeight: 'lineHeight-7',
  },
  'text-xl/lineHeight-7/font-book': {
    fontFamily: 'body',
    fontWeight: 'normal',
    fontSize: 'text-xl',
    lineHeight: 'lineHeight-7',
  },
  'text-xl/lineHeight-7/font-normal': {
    fontFamily: 'body',
    fontWeight: 'normal',
    fontSize: 'text-xl',
    lineHeight: 'lineHeight-7',
  },
  'text-xl/lineHeight-7/font-medium': {
    fontFamily: 'body',
    fontWeight: 'medium',
    fontSize: 'text-xl',
    lineHeight: 'lineHeight-7',
  },
  'text-xl/lineHeight-7/font-semibold': {
    fontFamily: 'body',
    fontWeight: 'semibold',
    fontSize: 'text-xl',
    lineHeight: 'lineHeight-7',
  },
  'text-xl/lineHeight-7/font-bold': {
    fontFamily: 'body',
    fontWeight: 'bold',
    fontSize: 'text-xl',
    lineHeight: 'lineHeight-7',
  },
  'text-xl/lineHeight-7/font-black': {
    fontFamily: 'body',
    fontWeight: 'black',
    fontSize: 'text-xl',
    lineHeight: 'lineHeight-7',
  },
  'text-2xl/lineHeight-8/font-book': {
    fontFamily: 'body',
    fontWeight: 'book',
    fontSize: 'text-2xl',
    lineHeight: 'lineHeight-8',
  },
  'text-2xl/lineHeight-8/font-normal': {
    fontFamily: 'body',
    fontWeight: 'normal',
    fontSize: 'text-2xl',
    lineHeight: 'lineHeight-8',
  },
  'text-2xl/lineHeight-8/font-medium': {
    fontFamily: 'body',
    fontWeight: 'medium',
    fontSize: 'text-2xl',
    lineHeight: 'lineHeight-8',
  },
  'text-2xl/lineHeight-8/font-semibold': {
    fontFamily: 'body',
    fontWeight: 'semibold',
    fontSize: 'text-2xl',
    lineHeight: 'lineHeight-8',
  },
  'text-2xl/lineHeight-8/font-bold': {
    fontFamily: 'body',
    fontWeight: 'bold',
    fontSize: 'text-2xl',
    lineHeight: 'lineHeight-8',
  },
  'text-2xl/lineHeight-8/font-black': {
    fontFamily: 'body',
    fontWeight: 'black',
    fontSize: 'text-2xl',
    lineHeight: 'lineHeight-8',
  },
  'text-3xl/lineHeight-9/font-book': {
    fontFamily: 'body',
    fontWeight: 'book',
    fontSize: 'text-3xl',
    lineHeight: 'lineHeight-9',
  },
  'text-3xl/lineHeight-9/font-normal': {
    fontFamily: 'body',
    fontWeight: 'normal',
    fontSize: 'text-3xl',
    lineHeight: 'lineHeight-9',
  },
  'text-3xl/lineHeight-9/font-medium': {
    fontFamily: 'body',
    fontWeight: 'medium',
    fontSize: 'text-3xl',
    lineHeight: 'lineHeight-9',
  },
  'text-3xl/lineHeight-9/font-semibold': {
    fontFamily: 'body',
    fontWeight: 'semibold',
    fontSize: 'text-3xl',
    lineHeight: 'lineHeight-9',
  },
  'text-3xl/lineHeight-9/font-bold': {
    fontFamily: 'body',
    fontWeight: 'bold',
    fontSize: 'text-3xl',
    lineHeight: 'lineHeight-9',
  },
  'text-3xl/lineHeight-9/font-black': {
    fontFamily: 'body',
    fontWeight: 'black',
    fontSize: 'text-3xl',
    lineHeight: 'lineHeight-9',
  },
  'text-4xl/lineHeight-10/font-book': {
    fontFamily: 'body',
    fontWeight: 'book',
    fontSize: 'text-4xl',
    lineHeight: 'lineHeight-10',
  },
  'text-4xl/lineHeight-10/font-normal': {
    fontFamily: 'body',
    fontWeight: 'normal',
    fontSize: 'text-4xl',
    lineHeight: 'lineHeight-10',
  },
  'text-4xl/lineHeight-10/font-medium': {
    fontFamily: 'body',
    fontWeight: 'medium',
    fontSize: 'text-4xl',
    lineHeight: 'lineHeight-10',
  },
  'text-4xl/lineHeight-10/font-semibold': {
    fontFamily: 'body',
    fontWeight: 'semibold',
    fontSize: 'text-4xl',
    lineHeight: 'lineHeight-10',
  },
  'text-4xl/lineHeight-10/font-bold': {
    fontFamily: 'body',
    fontWeight: 'bold',
    fontSize: 'text-4xl',
    lineHeight: 'lineHeight-10',
  },
  'text-4xl/lineHeight-10/font-black': {
    fontFamily: 'body',
    fontWeight: 'black',
    fontSize: 'text-4xl',
    lineHeight: 'lineHeight-10',
  },
  'text-5xl/lineHeight-11/font-book': {
    fontFamily: 'body',
    fontWeight: 'book',
    fontSize: 'text-5xl',
    lineHeight: 'lineHeight-11',
  },
  'text-5xl/lineHeight-11/font-normal': {
    fontFamily: 'body',
    fontWeight: 'normal',
    fontSize: 'text-5xl',
    lineHeight: 'lineHeight-11',
  },
  'text-5xl/lineHeight-11/font-medium': {
    fontFamily: 'body',
    fontWeight: 'medium',
    fontSize: 'text-5xl',
    lineHeight: 'lineHeight-11',
  },
  'text-5xl/lineHeight-11/font-semibold': {
    fontFamily: 'body',
    fontWeight: 'semibold',
    fontSize: 'text-5xl',
    lineHeight: 'lineHeight-11',
  },
  'text-5xl/lineHeight-11/font-bold': {
    fontFamily: 'body',
    fontWeight: 'bold',
    fontSize: 'text-5xl',
    lineHeight: 'lineHeight-11',
  },
  'text-5xl/lineHeight-11/font-black': {
    fontFamily: 'body',
    fontWeight: 'black',
    fontSize: 'text-5xl',
    lineHeight: 'lineHeight-11',
  },
  'text-6xl/lineHeight-12/font-book': {
    fontFamily: 'body',
    fontWeight: 'book',
    fontSize: 'text-6xl',
    lineHeight: 'lineHeight-12',
  },
  'text-6xl/lineHeight-12/font-normal': {
    fontFamily: 'body',
    fontWeight: 'normal',
    fontSize: 'text-6xl',
    lineHeight: 'lineHeight-12',
  },
  'text-6xl/lineHeight-12/font-medium': {
    fontFamily: 'body',
    fontWeight: 'medium',
    fontSize: 'text-6xl',
    lineHeight: 'lineHeight-12',
  },
  'text-6xl/lineHeight-12/font-semibold': {
    fontFamily: 'body',
    fontWeight: 'semibold',
    fontSize: 'text-6xl',
    lineHeight: 'lineHeight-12',
  },
  'text-6xl/lineHeight-12/font-bold': {
    fontFamily: 'body',
    fontWeight: 'bold',
    fontSize: 'text-6xl',
    lineHeight: 'lineHeight-12',
  },
  'text-6xl/lineHeight-12/font-black': {
    fontFamily: 'body',
    fontWeight: 'black',
    fontSize: 'text-6xl',
    lineHeight: 'lineHeight-12',
  },
  'component-heading-large/6xl': {
    fontFamily: 'heading',
    fontWeight: 'normal',
    fontSize: 'component-heading-large/6xl',
    lineHeight: '120%',
  },
  'component-heading-large/5xl': {
    fontFamily: 'heading',
    fontWeight: 'normal',
    fontSize: 'component-heading-large/5xl',
    lineHeight: '120%',
  },
  'component-heading-large/4xl': {
    fontFamily: 'heading',
    fontWeight: 'normal',
    fontSize: 'component-heading-large/4xl',
    lineHeight: '120%',
  },
  'component-heading-large/3xl': {
    fontFamily: 'heading',
    fontWeight: 'normal',
    fontSize: 'component-heading-large/3xl',
    lineHeight: '120%',
  },
  'component-heading-large/2xl': {
    fontFamily: 'heading',
    fontWeight: 'normal',
    fontSize: 'component-heading-large/2xl',
    lineHeight: '120%',
  },
  'component-heading-large/xl': {
    fontFamily: 'heading',
    fontWeight: 'normal',
    fontSize: 'component-heading-large/xl',
    lineHeight: '120%',
  },
  'component-heading-large/lg': {
    fontFamily: 'heading',
    fontWeight: 'medium',
    fontSize: 'component-heading-large/lg',
    lineHeight: '120%',
  },
  'component-heading-large/md': {
    fontFamily: 'heading',
    fontWeight: 'normal',
    fontSize: 'component-heading-large/md',
    lineHeight: '120%',
  },
  'component-heading-large/sm': {
    fontFamily: 'heading',
    fontWeight: 'normal',
    fontSize: 'component-heading-large/sm',
    lineHeight: '120%',
  },
  'component-heading-large/xs': {
    fontFamily: 'heading',
    fontWeight: 'normal',
    fontSize: 'component-heading-large/xs',
    lineHeight: '120%',
  },
  'component-heading-small/6xl': {
    fontFamily: 'heading',
    fontWeight: 'normal',
    fontSize: 'component-heading-small/6xl',
    lineHeight: '100%',
  },
  'component-heading-small/5xl': {
    fontFamily: 'heading',
    fontWeight: 'normal',
    fontSize: 'component-heading-small/5xl',
    lineHeight: '100%',
  },
  'component-heading-small/4xl': {
    fontFamily: 'heading',
    fontWeight: 'normal',
    fontSize: 'component-heading-small/4xl',
    lineHeight: '100%',
  },
  'component-heading-small/3xl': {
    fontFamily: 'heading',
    fontWeight: 'normal',
    fontSize: 'component-heading-small/3xl',
    lineHeight: '100%',
  },
  'component-heading-small/2xl': {
    fontFamily: 'heading',
    fontWeight: 'normal',
    fontSize: 'component-heading-small/2xl',
    lineHeight: '100%',
  },
  'component-heading-small/xl': {
    fontFamily: 'heading',
    fontWeight: 'normal',
    fontSize: 'component-heading-small/xl',
    lineHeight: '100%',
  },
  'component-heading-small/lg': {
    fontFamily: 'heading',
    fontWeight: 'normal',
    fontSize: 'component-heading-small/lg',
    lineHeight: '100%',
  },
  'component-heading-small/md': {
    fontFamily: 'heading',
    fontWeight: 'normal',
    fontSize: 'component-heading-small/md',
    lineHeight: '100%',
  },
  'component-heading-small/sm': {
    fontFamily: 'heading',
    fontWeight: 'normal',
    fontSize: 'component-heading-small/sm',
    lineHeight: '100%',
  },
  'component-heading-small/xs': {
    fontFamily: 'heading',
    fontWeight: 'normal',
    fontSize: 'component-heading-small/xs',
    lineHeight: '100%',
  },
  'component-paragraph-headline/6xl': {
    fontFamily: 'body',
    fontWeight: 'medium',
    fontSize: 'component-paragraph-headline/6xl',
    lineHeight: '120%',
  },
  'component-paragraph-headline/5xl': {
    fontFamily: 'body',
    fontWeight: 'medium',
    fontSize: 'component-paragraph-headline/5xl',
    lineHeight: '120%',
  },
  'component-paragraph-headline/4xl': {
    fontFamily: 'body',
    fontWeight: 'medium',
    fontSize: 'component-paragraph-headline/4xl',
    lineHeight: '120%',
  },
  'component-paragraph-headline/3xl': {
    fontFamily: 'body',
    fontWeight: 'medium',
    fontSize: 'component-paragraph-headline/3xl',
    lineHeight: '120%',
  },
  'component-paragraph-headline/2xl': {
    fontFamily: 'body',
    fontWeight: 'medium',
    fontSize: 'component-paragraph-headline/2xl',
    lineHeight: '120%',
  },
  'component-paragraph-headline/xl': {
    fontFamily: 'body',
    fontWeight: 'medium',
    fontSize: 'component-paragraph-headline/xl',
    lineHeight: '120%',
  },
  'component-paragraph-headline/lg': {
    fontFamily: 'body',
    fontWeight: 'medium',
    fontSize: 'component-paragraph-headline/lg',
    lineHeight: '120%',
  },
  'component-paragraph-headline/md': {
    fontFamily: 'body',
    fontWeight: 'medium',
    fontSize: 'component-paragraph-headline/md',
    lineHeight: '120%',
  },
  'component-paragraph-headline/sm': {
    fontFamily: 'body',
    fontWeight: 'medium',
    fontSize: 'component-paragraph-headline/sm',
    lineHeight: '120%',
  },
  'component-paragraph-headline/xs': {
    fontFamily: 'body',
    fontWeight: 'medium',
    fontSize: 'component-paragraph-headline/xs',
    lineHeight: '120%',
  },
  'component-text/6xl': {
    fontFamily: 'body',
    fontWeight: 'normal',
    fontSize: 'component-text/6xl',
    lineHeight: '150%',
  },
  'component-text/5xl': {
    fontFamily: 'body',
    fontWeight: 'normal',
    fontSize: 'component-text/5xl',
    lineHeight: '150%',
  },
  'component-text/4xl': {
    fontFamily: 'body',
    fontWeight: 'normal',
    fontSize: 'component-text/4xl',
    lineHeight: '150%',
  },
  'component-text/3xl': {
    fontFamily: 'body',
    fontWeight: 'normal',
    fontSize: 'component-text/3xl',
    lineHeight: '150%',
  },
  'component-text/2xl': {
    fontFamily: 'body',
    fontWeight: 'normal',
    fontSize: 'component-text/2xl',
    lineHeight: '150%',
  },
  'component-text/xl': {
    fontFamily: 'body',
    fontWeight: 'normal',
    fontSize: 'component-text/xl',
    lineHeight: '150%',
  },
  'component-text/lg': {
    fontFamily: 'body',
    fontWeight: 'normal',
    fontSize: 'component-text/lg',
    lineHeight: '150%',
  },
  'component-text/md': {
    fontFamily: 'body',
    fontWeight: 'normal',
    fontSize: 'component-text/md',
    lineHeight: '150%',
  },
  'component-text/sm': {
    fontFamily: 'body',
    fontWeight: 'normal',
    fontSize: 'component-text/sm',
    lineHeight: '150%',
  },
  'component-text/xs': {
    fontFamily: 'body',
    fontWeight: 'normal',
    fontSize: 'component-text/xs',
    lineHeight: '150%',
  },
  'doodle-text/xl': {
    fontFamily: 'grace',
    fontWeight: 'normal',
    fontSize: 'text-xl',
    lineHeight: '120%',
  },
  'component-article-headline/xl': {
    fontFamily: 'body',
    fontWeight: 'medium',
    fontSize: 'text-md',
    lineHeight: '26px',
  },
  'component-article-headline/2xl': {
    fontFamily: 'body',
    fontWeight: 'medium',
    fontSize: 'text-2xl',
    lineHeight: '31.2px',
  },
  'component-article-headline/3xl': {
    fontFamily: 'body',
    fontWeight: 'medium',
    fontSize: 'component-paragraph-headline/3xl',
    lineHeight: '41.6px',
  },
  'contactPerson/title': {
    fontSize: '26px',
    color: colors.purple[500],
    lineHeight: '120%',
  },
  'contactPerson/role': {
    fontSize: '12px',
    color: colors.purple[500],
    lineHeight: '120%',
  },
};
