import Image, { ImageProps } from 'next/image';

import { ImageFragment } from '@/graphql/generated';

export interface StrapiImageProps extends Omit<ImageProps, 'src' | 'alt'> {
  image: ImageFragment;
  alt?: string;
}

export const StrapiImage = ({ image, alt, ...props }: StrapiImageProps) => {
  const { url, width, height, alternativeText, caption } = image.data?.attributes || {};

  if (!url) {
    return null;
  }

  return (
    <Image
      src={url}
      alt={alternativeText || caption || alt || ''}
      width={width as number}
      height={height as number}
      {...props}
    />
  );
};
